/**
 *  20210120 GC 189-4864 Added appDomain
 * 20210216 GC 189-4864 Changed appRoot to http://localhost:4200
 * 20231109 SS Changed appRoot and authUrl to http://localhost:8080
 */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const appConfig = {
  authUrl: 'https://xenohr.lk/xeno',
  appRoot: 'https://xenohr.lk/xeno',
  getIpDetails: 'https://api.ipgeolocation.io/ipgeo?apiKey=70c92c36925640239326a8aa81681609&fields=country_code2,country_name',
  appDomain:'xenohr'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
