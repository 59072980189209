/*
* 20201123 HA 194-4859 created to keep employee profile picture related service methods and paramters.
*/

import { Injectable } from '@angular/core';

@Injectable()
export class ProfilePictureService {
    private fileToUpload: any;
    private croppedImage: any;

    setFileToUpload(fileToUpload: any) {
        this.fileToUpload = fileToUpload;
    }

    getFileToUpload() {
        return this.fileToUpload;
    }

    setCroppedImage(croppedImage: any) {
        this.croppedImage = croppedImage;
    }

    getCroppedImage() {
        return this.croppedImage;
    }
}