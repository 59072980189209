/**
 * 20201022 GC 173-4850  added inline comments
 * 20201118 GC 190-4871 added updatePermissions()
 * 20210106 GC 189-4864 fixed prod build issues, Add getUserToken(), checkPortalname()
 * 20210223 GC 189-4864 Added refreshToken()
 * 20210503 GC 246-4905 Added logout API request
 * 20240401 SV XENOV2-638 MyLeave dashboard
 * 20240425 SS When initial login of the super admin the dashboard permission is not coming, so the direction should be set to subscription
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { appConfig } from 'src/environments/environment';
import { HttpService } from '../http/http.service';
import { Router } from '@angular/router';
import { ENDPOINTS } from '../util/api-endpoints';
import { HttpHeaders, HttpRequest, HttpClient  } from '@angular/common/http';
import { MainMenuSelectService } from '../services/main-menu-select.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    cachedRequests: Array<HttpRequest<any>> = [];

    constructor(
        private httpService: HttpService, 
        private router: Router, 
        private http:HttpClient,
        private mainMenuService: MainMenuSelectService,
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    /**
     * return current user
     */
    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    /**
     *
     * Using to log user to system with credentials on login page
     */
    login(username: string, password: string) {
        return this.httpService.httpPost(`${appConfig.authUrl}/users/authenticate`, { username, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('currentUser', user.username);
                    localStorage.setItem('permissions', user.permissions);
                    localStorage.setItem('cmpCode', user.cmpCode);
                    localStorage.setItem('companyName', user.companyName);
                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }

    /**
     * Handle initial login from signup page after password creation
     */
    initialLogin(user, empStatus) {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('currentUser', user.username);
        localStorage.setItem('permissions', user.permissions);
        localStorage.setItem('cmpCode', user.cmpCode);
        localStorage.setItem('companyName', user.companyName);
        this.currentUserSubject.next(user);
      //  this.startRefreshTokenTimer();
        if(empStatus === 'COMPP'){
            this.mainMenuService.setCurrentIndex(711);
            this.router.navigateByUrl('company/genaral/company-details');
        } else if (empStatus === 'COMPA'){
            this.mainMenuService.setCurrentIndex(2);
            this.router.navigateByUrl('/');
        } else{
            this.mainMenuService.setCurrentIndex(0);
            this.router.navigateByUrl('/subscription');
        }

    }

    /**
     * Method is for change permissions of user dynamically
     *
     */
    updatePermissions(permissions) {
        const user = JSON.parse(localStorage.getItem('user')); // get stored user
        user.permissions = permissions; // Assign updated permissions
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('currentUser', user.username);
        localStorage.setItem('permissions', user.permissions);
        this.currentUserSubject.next(user);
    }

    portalLogout(portalName) {
        // remove user from local storage to log user out
        const url  = appConfig.appRoot.concat(ENDPOINTS.logout);
        const user = JSON.parse(localStorage.getItem('user')); // get stored user
        return this.httpService.httpPut(url, { 'portalName':portalName , username : user.username })
        .pipe(map(result => {
            // login successful if there's a jwt token in the response
            if (result.apiStatusCode == 2) {
                localStorage.removeItem('user');
                localStorage.removeItem('currentUser');
                localStorage.removeItem('user');
                localStorage.removeItem('permissions');
                localStorage.removeItem('companyName');
                this.currentUserSubject.next(null);
            }
            this.router.navigate(['/login']);
        }));
        
    }

    /** general logout */
    logout() {
        // remove user from local storage to log user out
        const url  = appConfig.appRoot.concat(ENDPOINTS.logout);
        const user = JSON.parse(localStorage.getItem('user')); // get stored user
       
        // login successful if there's a jwt token in the response
        
        localStorage.removeItem('user');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
        localStorage.removeItem('companyName');
        this.currentUserSubject.next(null);
    
        this.router.navigate(['/login']);
    }
    /**
     * Check whther the prtal name exist
     * @param portalName
     */
    checkPortalname(portalName){
        const url = appConfig.appRoot.concat(ENDPOINTS.validatePortal);
        return this.httpService.httpGet(url+'?portalName='+portalName);
    }

    /**
     * get the token for the user
     * @param body
     */
    getUserToken(body){
        const url  = appConfig.appRoot.concat(ENDPOINTS.getToken);
        return this.httpService.httpPost(url, body);
    }

    logUser(body){
        const url  = appConfig.appRoot.concat(ENDPOINTS.logUser);
        return this.httpService.httpPost(url, body);
    }

    /**
     * Refresh the token uing expired token
     * @param request
     */
    refreshToken(request) {
        const headers = new HttpHeaders().set('isRefreshToken',`true`);
        const url = appConfig.appRoot.concat(ENDPOINTS.refreshToken);
        return this.httpService.httpGet(url,{ headers: headers }).subscribe(
            res=>{
                const user = JSON.parse(localStorage.getItem('user')); // get stored user
                user.jwtToken = res.data;

                localStorage.setItem('user', JSON.stringify(user));
                this.currentUserSubject.next(user);
                this.retryFailedRequests(request);
            });
    }

    // Get user email by token
    getEmail(token:string) {
      const url = appConfig.appRoot.concat(ENDPOINTS.getEmail.replace("${token}",token));
      return this.httpService.httpGet(url);
  }


/**
 * Add failed request to an array
 * @param request
 */
    public collectFailedRequest(request): void {
        this.cachedRequests.push(request);
      }

    /**
     * retry failed request
     * @param request
     */
    public retryFailedRequests(request): void {
           this.http.request(request).subscribe((response) => {
            // need to subscribe to observer in order to "retry"  request
        });

      }
}
