import { Component, ViewChild,ElementRef, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MainMenuSelectService } from '../services/main-menu-select.service';
import { Router } from '@angular/router';
import { MenuItems } from '../models/menu-item';

@Component({
    selector: 'conf',
    template: `
          <div id="abx0819em1" class="modal-header {{headerColor}}">
              <h4 id="abx0819h41" class="modal-title"><span class="fa {{icon}}"></span>&nbsp;&nbsp;{{title}}</h4>
              <button type="button" id="abx0819bu1" class="close" aria-label="Close" tabindex="-1" (click)="this.activeModal.close(false)">
                  <span aria-hidden="true" id="abx0819sp1">&times;</span>
              </button>
          </div>
          <div id="abx0819di1" class="modal-body">

              <p id="abx0819p1" class="text-muted" >{{confMessage}}</p>
          </div>
          <div id="abx0819di2" class="modal-footer">
              <button type="button" id="abx0819bu2" class="btn btn-primary update-n-btn" (click)="this.activeModal.close(true)" [disabled]="errorMsg">Yes</button>
              <button type="button" id="abx0819bu3" #cancelBtn class="btn btn-primary can-btn" (click)="this.activeModal.close(false); cancel();">No</button>
          </div>
      `,
    styles: [`

`],
})
export class ConfirmationComponent {
    confMessage;
    errorMsg;
    headerColor;
    icon;
    title
    @ViewChild('cancelBtn', {static: false}) inputEl: ElementRef;
    constructor(public activeModal: NgbActiveModal,
        private mainMenuSelectService: MainMenuSelectService,
        private router: Router,
        private menuItems: MenuItems) {

    }

    ngAfterViewInit() {
        setTimeout(() => this.inputEl.nativeElement.focus());
  }

    cancel() {
        var index = 0;
        this.menuItems.mainMenuItems.forEach(menu => {
            if (this.router.url.split("/", 2)[1] == menu.mainLink) {
                index = menu.menuIndex;
            }
        });
        this.mainMenuSelectService.setDisplayMenusIndex(index);
    }
}
