/**
 * 20210409 PU 291-4911 this contains common messages
 * 20210810 HA 265-4921 unsave warning common message changed.
 * 20210913 SR 264-4931 unsave warning common message added.
 * 20211005 GC 791-4964 moved messages
 * 20211112 GC 183-4929 Added new massages
 * 20211209 HA 183-4929 added WORKING_DAYS_TYPE_CHANGE.
 * 20211210 HA 183-4929 added WORK_BASIS_CHANGE.
 * 20220321 HA 510-4942 added SHIFT_TYPE_CHANGE.
 * 20231027 SV XENOV2-434 added PLEASE_SELECT_EMP_ROLE
 * 20231107 SS XENOV2-434 5169 added EMAIL_EXIST
 * 20231107 SS XENOV2-434 5169 added DEFAULT_DATE_ERROR
 * 20231122 SS XENOV2-434 5169 added HEADS_EPF_NUM, EPF_NUM_EXIST, GREATER_THAN_0, EMP_NUM_EXIST
 * 20231123 SS XENOV2-434 5169 added NIC_INVALID
 * 20231124 SS XENOV2-434 5169 Changes to the NIC Exist Error Message
 * 20231113 SV XENOV2-436 added SAVE_CHANGES Under LEAVE
 * 20231211 SS XENOV2-425 added common messages for apply leave modal
 * 20240104 SS 514-5189 Added ATTACHMENT_MAX_FILE_SIZE error message
 * 20240123 SS 513-5203 added LOWER_THAN_TOTAL for utilized leave counts in employee add and edit
 * 20240123 SS XENOV2-585 'The warning message is not displayed if the user tries to inactivate a HOD who has been assigned as a reporting officer.' Bug fixed
 * 20240215 SS XENOV2-594 'Validtaion failed for some feilds' Bug fixed
 * 20240305 SS 623-5211 Add leave type validation error messages
 * 20240307 SS 624-5214 Changed NUMBER_LESS_THAN, NUMBER_GREATER_THAN and NUMBER_GREATER_THAN
 * 20240311 SV XENOV2-616 Attendance bulk upload added DOWNLOAD_SAMPLE_ATTENDANCE
 * 20240328 SS 649-5218 Add error message when live validation of Max Date triggers
 * 20240410 SS 653-5222 Add error message when live validation of max consecutive leave days error trigger
 * 20240710 SS 756-5251 Add error message when live validation of invalid leave days error trigger
 * 20240731 SS 752-5249 Front end development of attendance time configuration
 * 20240718 DG 732-5227 Add common 'already exists' message
 * 20240725 DG 687-5240 Add USERROLE section
 * 20240802 SS 661 Add commenting to the payroll module
 * 20240814 SS 661 BA Review Changes fixed
 * 20240912 SS 780 Assign employees to payroll development
 */
export const Messages = {
  SAVE_CHANGES: 'Are you sure you want to save the changes?',
  UNSAVED_WARNING: 'All unsaved changes will be lost. Are you sure you want to proceed?', // unsaved warning message
  PROCEED_WARNING: 'Are you sure you want to proceed?', // unsaved warning message
  EXISTS: 'already exists', // Common 'already exists' message
  REQUIRED: 'is required', // Common 'is required' message
  EMP: {
    EPF_NUM_LENGTH: ' EPF number length should be less than or equal to 20',
    REQUIRED : 'is required',
    EPF_NUM_PATTERN:'Please enter a valid epf number',
    EMP_NUM_LENGTH :' Employee number length should be less than or equal to 20',
    EMP_NUM_PATTERN:'Please enter a valid employee number',
    PLEASE_SELECT_SALUTATION: 'Please select a salutation',
    FIRST_NAME_LENGTH : 'First name length should be less than or equal to 50',
    FIRST_NAME_PATTERN:'Please enter a valid first name',
    MIDDLE_NAME_LENGTH: 'Middle name length should be less than or equal to 50',
    MIDDLE_NAME_PATTERN:'Please enter a valid middle name',
    LAST_NAME_LENGTH: 'Last name length should be less than or equal to 50',
    LAST_NAME_PATTERN:'Please enter a valid last name',
    FULL_NAME_LENGTH:'Full name length should be less than or equal to 250',
    FULL_NAME_PATTERN:'Please enter a valid full name',
    NAME_INITIAL_LENGTH:'Name with initials length should be less than or equal to 80',
    NAME_INITIAL_PATTERN:'Please enter a valid name with initials',
    PLEASE_SELECT_GENDER: 'Please select a gender',
    DOB_FORMAT:'Date of birth is required in the format YYYY-MM-DD',
    DOB_LESS_THAN_CURRENT:'Date of birth should be less than the current date',
    INVALID_DOB:'Please enter a valid date of birth',
    PLEASE_SELECT_DESIGNATION:'Please select a designation',
    JOIN_DATE_REQUIRED:'Joined date is required in the format YYYY-MM-DD',
    JOIN_DATE_GREATER_THAN_CURRENT:'Joined date should be greater than the date of birth',
    JOIN_DATE_LESS_THAN_CURRENT:'Joined date should be less than or equal to the current date',
    ENTER_VALID_JOIN_DATE:'Please enter a valid joined date',
    PLEASE_SELECT_EMP_CAT:'Please select an employee category',
    YEAR_CANNOT_GREATER_99:'Years cannot be greater than 99',
    YEAR_CANNO_LESS_0:'Years cannot be less than 0',
    YEAR_SHOULD_NUMERIC:'Years should be numeric value',
    YEAR_VALUE_REQURED:'Year value is required',
    YEAR_MONTH_CANNOT_0:'Year and month both cannot be zero value at the same time',
    MONTH_CANNOT_GREATER_11:'Months cannot be greater than 11',
    MONTH_CANNO_LESS_0:'Months cannot be less than 0',
    MONTH_SHOULD_NUMERIC:'Months should be numeric value',
    MONTH_VALUE_REQURED:'Month value is required',
    MONTH_MONTH_CANNOT_0:'Year and month both cannot be zero value at the same time',
    FROM_DATE_FORMAT:'From date is required in the format YYYY-MM-DD',
    FROM_DATE_GREATER_THAN_JOIN:'From date should be greater than or equal to the joined date',
    ENTER_VALID_FROM_DATE:'Please enter a valid from date',
    SELECT_DEPARTMENT:'Please select a department',
    SELECT_ACT:'Please select an employment act',
    SALARY_NUMERIC:'Basic salary should be numeric value',
    SALARY_GREATER_0:'Basic salary should be greater than 0',
    SALARY_MORE_THAN_2_DECIMAL:'Basic salary can not have more than 2 decimal points',
    SALARY_LESS_THAN_10_DIGITS:'Basic salary length should be less than 10 digits including 2 decimal points',
    SELECT_STATUS:'Please select a status',
    STATUS_HAS_EMP:'Employee is currently assigned as a Reporting Officer and cannot be deactivated',
    SELECT_STATUS_TYPE:'Please select a status type',
    EFFECTIVE_DATE_REQUIRED:'Effective date is required in the format YYYY-MM-DD',
    EFFECT_DATE_GRATER_JOIN_DATE:'Effective date should be greater than or equal to the joined date',
    ENTER_VALID_EFF_DATE:'Please enter a valid effective date',
    NIC_GREATER_THAN_10:'NIC number should be greater than or equal to 10',
    NIC_LESS_THAN_12:'NIC number should be less than or equal to 12',
    NIC_GREATER_DOB:'NIC issued date should be greater than the date of birth',
    ISSUE_DATE_LESS_THAN_CURRENT:'NIC issued date should be less than or equal to the current date',
    ENTER_VALID_DATE:'Please enter the date in the format YYYY-MM-DD',
    LICENSE_LESS_THAN_25:'Driving license number should be less than or equal to 25',
    EXP_DATE_GREATER_DOB:'Driving license expiry date should be greater than the date of birth',
    PASSPORT_LESS_THAN_15:'Passport number should be less than or equal to 15',
    EXP_DATE_GREATER_THAN_DOB:'Passport expiry date should be greater than the date of birth',
    ADDRESS_LESS_THAN_250:'Permanent address length should be less than or equal to 250',
    RESIDENTIAL_ADDRESS_LESS_THAN_250:'Residential address length should be less than or equal to 250',
    ENTER_VALID_EMAIL_ADDRESS:'Please enter a valid email address',
    EMAIL_LESS_THAN_100:'Email address length should be less than or equal to 100',
    ENTER_VALID_MOBILE:'Please enter a valid mobile number',
    ENTER_VALID_LANDLINE:'Please enter a valid landline number',
    LENGHT_LESS_THAN_100:'Company name length should be less than or equal to 100',
    LENGTH_LESS_THAN_250:'Company address length should be less than or equal to 250',
    ENTER_VALID_EXTENSION:'Please enter a valid extension',
    CONTACT_NAME_LENGTH_LESS_100:'Contact name length should be less than or equal to 100',
    ADD_MORE_OPTIONAL:'Add more optional bank accounts',
    BANK_NAME_LESS_THAN_50:'Bank name should be less than or equal to 50',
    BANK_CODE_LESS_THAN_20:'Bank code should be less than or equal to 20',
    BRANCH_NAME_LESS_THAN_30:'Branch name  should be less than or equal to 30',
    BRANCH_CODE_LESS_THAN_20:'Branch code  should be less than or equal to 20',
    ACCOUNT_NUMBER_LESS_THAN_50:'Account number should be less than or equal to 50',
    AMOUNT_LENGTH_LESS_20:'Amount length should be less than or equal to 10 digits including 2 decimal points',
    AMOUNT_SHOULD_NUMERIC:'Amount should be a numeric value',
    AMOUNT_SHOULD_LESS_THAN_2_DECIMAL:'Amount should not more than 2 decimal points',
    NO_RECORDS:'No matching records found',
    DOWNLOAD_SAMPLE:'Download sample template for import',
    DOWNLOAD_SAMPLE_ATTENDANCE:'Download template',
    UPLOAD_XLS:'Please upload a file in the format xls/xlsx',
    MAX_SIZE :'Maximum file size allowed to upload is 5MB',
    INSTRUCTION_01:'01. Maximum upload file size is 5 MB.',
    INSTRUCTION_02:'03. Only allowed xls and xlsx files to upload.',
    PLEASE_SELECT_EMP_ROLE:'Please select an user role',
    EMAIL_EXIST:'Email already exists',
    DEFAULT_DATE_ERROR:'should not be system default date',
    HEADS_EPF_NUM:'Please select a reporting officer',
    EPF_NUM_EXIST:'EPF Number already exists',
    GREATER_THAN_0:'should be greater than 0',
    LOWER_THAN_TOTAL:'should be lower than ',
    EMP_NUM_EXIST:'Employee Number already exists',
    NIC_EXIST:'NIC already exists',
    NIC_INVALID:'NIC should be 9 digits with V or X, or 12 digits',
  },
  COMP:{
    COMP_DETAILS:{
      REG_NO_LENGTH_LESS_100:'Company registration number length should be less than or equal to 100',
      EMP_NO_LESS_20:'Employer number length should be less than or equal to 20',
      REQUIRED:'is required',
      ADDRESS_01_250:'Address line 01 length should be less than or equal to 250',
      ADDRESS_02_250:'Address line 02 length should be less than or equal to 250',
      CITY_LENGTH_250:'City length should be less than or equal to 250',
      ZIP_CODE_16:'Zipcode length should be less than or equal to 16',
      CONTACT_NAME_250:'Contact person’s name max length should be less than or equal to 250',
      CONTACT_NUM_16:'Contact person’s phone number length should be less than or equal to 16',
      ENTER_VALID_PHONE:'Please enter a valid phone number',
      WEBSITE_MAX_LENGTH:'Company website max length should be less than or equal to 253',
      ENTER_VALID_WEB:'Please enter a valid website address',
      COMPANY_PHONE_16:'Company phone number length should be less than or equal to 16',
      COMP_EMAIL_LENGTH:'Company email length should be less than or equal to 100',
      ENTER_VALID_COMP_EMAIL:' Please enter a valid company email',
      COMP_FAX_MAX_LENGTH:'Company fax max length should be less than or equal to 16',
      BANK_NAME_LENGTH:'Bank name should be less than or equal to 50',
      BANK_CODE_LENGTH:'Bank code should be less than or equal to 20',
      BRANCH_NAME_LENGTH:'Branch name should be less than or equal to 30',
      BRANCH_CODE_LENGTH:'Branch code should be less than or equal to 20',
      ACCOUNT_NUM_LENGTH:'Account number should be less than or equal to 50',

    },
    DEPT:{
      REQUIRED:' is required',
      DEPT_NAME_EXIST:'Department name already exists',
      DEPT_NAME_LENGTH:'Department name length should be less than or equal to 100',
      ENTER_VALID_EMAIL:'Please enter a valid email alias',
      EMAIL_LENGTH:'Email alias length should be less than or equal to 100',
      DESCRIPTION_LENGTH:'Description length should be less than or equal to 250',

    },
    SUB_DEPT:{
      ALREDY_EXIST:'Sub-department name already exists under the same parent department',
      SUBDEPT_LENGTH:'Sub-department name length should be less than or equal to 100',
      INVALID_EMAIL:'Please enter a valid email alias',
      EMAIL_LENGTH:'Email alias length should be less than or equal to 100',
      DESC_LENGTH:'Description length should be less than or equal to 250',

    },
    SECTION:{
      REQUIRED:'is required',
      SECT_NAME_EXIST:'Section name already exists under the same parent sub-department',
      SECT_NAME_LENGTH:'Section name length should be less than or equal to 100',
      INVALID_EMAIL:'Please enter a valid email alias',
      EMAIL_LENGTH:'Email alias length should be less than or equal to 100',
      DESCRIPTION_LENGTH:'Description length should be less than or equal to 250',

    },
    DESIGNATION:{
      REQUIRED:'is required',
      DESIGNAME_EXIST:'Designation name already exists',
      DESIGNAME_LENGTH:'Designation name length should be less than or equal to 100',
      INVALID_EMAIL:'Please enter a valid email alias',
      EMAIL_LENGTH: 'Email alias length should be less than or equal to 100',
      DESCRIPTION_LENGTH:'Description length should be less than or equal to 250',

    },
    COST_CENTERS:{
      REQUIRED:'is required',
      COST_NAME_EXIST:'Cost center name already exists',
      COST_NAME_LENGTH:'Cost center name length should be less than or equal to 100',
      DESCRIPTION_LENGTH:'Description length should be less than or equal to 250',

    },
    CALENDAR:{
      REQUIRED: 'is required',
      TO_GRATER_THAN_FROM:'‘To’ date & time should be greater than ‘From’ date & time.',
      STDATE_GREATE_CURRENT:'Start Date should be greater than current date',
      SELECT_ST_DATE:'Please select start date first',
      DATE_GREATER_CURRENT:'Date should be greater than current date',
      ENDDATE_GREATER_CURRENT:'End Date should be greater than current date',
      DATE_FORMAT:'Date & Time is required in YYYY-MM-DD HH:MM format',
      EVENT_TYPE_LENGTH:'Event Type length should be less than or equal to 30',
      EVENT_TYPE_EXIST:'Event Type already exists.'
    },
    REGULATION:{
      REQUIRED: 'is required',
      REGULATION_ID_LENGTH:'Regulation ID length should be less than or equal to 10',
      REG_ID_NAME_EXIST:'Value already exists',
      REG_NAME_LENGTH:'Regulation Name length should be less than or equal to 50',
      REG_DESC_LENGTH:'Regulation Description length should be less than or equal to 200',
      REQUIRED_FIELD:'Required field',
      SELECT_GREATER_THAN:'Select a value greater than',
      SELECT_LESS_THAN:'Select a value less than',
      VALUE_EXISTS:'Value already exists',
      INVALID_FORMAT:'Enter whole numbers in HH:MM format.',
      WHMP_GREATER_WHPD: 'WHPM should be greater than WHPD.',
      OTHPM_GRETATER_OTHPD: 'OTHPM should be greater than OTHPD.',
      WHPBW_GREATER_WHPD:'WHPBW should be greater than WHPD.',
      OTHPBW_GREATER_OTHPD:'OTHPBW should be greater than OTHPD.',
      WHPW_GREATER_WHPD:'WHPW should be greater than WHPD.',
      OTHPW_GRATER_OTHPD:'OTHPW should be greater than OTHPD.',
      WHPM_LESS_THAN_2359:'Total of WHPM and OTHPM should be less than or equal to <Days Count * 23:59h>',
      WHPBW_LESS_THAN_2359:'Total of WHPBW and OTHPBW should be less than or equal to <Days Count * 23:59h>.',
      WHPW_LESS_THAN_2359:'Total of WHPW and OTHPW should be less than or equal to <Days Count * 23:59h>',
      WHPD_LESS_THAN_2359:'Total of WHPD and OTHPD should be less than or equal to 23:59h.',
      INVALID_VALUE:'Invalid value',
      WORKING_DAYS_TYPE_CHANGE: 'Information against previous applicable working days type will be lost. Are you sure you want to proceed?',
      WORK_BASIS_CHANGE: 'Information against previous work basis will be lost. Are you sure you want to proceed?'
    },
    INITIAL_SETUP:{
      REQUIRED:'is required',
      COMP_NAME_LENGTH:'Company name length should be less than or equal to 150',
      WEBSITE_LENGTH:'Company website length should be less than or equal to 253',
      INVALID_WEB_ADDRESS:'Please enter a valid website address',
      FULL_NAME_LENGTH:'Full name length should be less than or equal to 250',
      INVALID_EMAIL:'Please enter a valid email address',
      EMAIL_LENGTH:'Email length should be less than or equal to 100',
      SELECT_COUNTRY:'Please select a country',
      INVALID_PHONE_NUMBER:'Please enter a valid phone number',
      PHONE_NUMBER_LENGT:'Phone number length should be less than or equal to 16',
      EMAIL_SENT:'A confirmation email has been sent to your email account. Please follow up the instructions given to setup password'
    },
    SHIFT:{
      REQUIRED:'is required',
      SHIFT_NAME_LENGTH:'Shift name length should be less than or equal to 50',
      SHIFT_NAME_EXIST:'Shift name already exists',
      SHIFT_ID_LENGTH:'Shift id length should be less than or equal to 10',
      SHIFT_ID_EXIST:'Shift id already exists',
      SHIFT_DESC_LENGTH:'Shift description length should be less than or equal to 200',
      GAP_CHECKING_CHECKOUT:'The gap between Check-in and Check-out Times should not exceed 23.59 hours',
      CHECKING_LESS_THAN_CHECKOUT:'The Check-in time value cannot be equal or less than the Check-out time',
      INVALID_CHECKOUT_TIME:'Invalid check-out time',
      SELECTED_AFTER_CHECKING:'Selected value should be after shift’s check-in time and before shift’s check-out time',
      VALUE_CANNOT_0:'Values added against both minimum hours and minimum minutes fields cannot be 0',
      INVALID_VALUE:'Invalid hours value',
      SHORT_LEAVE_BETWEEN:'Short leave time should be between check-in and check-out times',
      ALREADY_EXIST:'already exists in the table',
      SHIFT_TYPE_CHANGE: 'Previous information against previous shift type will be lost. Are you sure you want to proceed?'
    }

  },
  ATTEN:{
    REQUIRED: 'is required',
    INVALID_TIME: 'is required in the format HH:MM',
    LESS_THAN: 'should be less than',
    LESS_THAN_EQUAL: 'should be less than or equal to',
    BEFORE: 'should be before',
  },
  LEAVE: {
    REQUIRED: 'is required',
    INSUFFICIENT_BALANCE: 'Insufficient Leave Balance',
    EXISTING_LEAVES: 'Selected dates overlap with existing leave',
    HOLIDAY: 'Cannot select a holiday as',
    INVALID_SEGMENTS: 'Invalid segment selection',
    ENTER_VALID_CONTACT: 'Please enter a valid contact number',
    AVAILABLE_BALANCE_EXCEEDS: 'No. of days exceeds the available leave days',
    MAX_COUNT_EXCEEDS: 'Maximum number of days exceeded',
    ATTACHMENT_MAX_FILE_SIZE: 'Attachment is too large. Please select a file size less than 5 MB.',
    CHAR_LENGTH_LESS_THAN:'length should be less than or equal to',
    NUMBER_GREATER_THAN:'should be greater than or equal to',
    NUMBER_LESS_THAN:'should be less than or equal to',
    DATE_EXCEEDING_MAX_DATE:'The leave dates should be before',   //20240328 SS 649-5218
    MAX_CONSEC_EXCEED:'You have applied the maximum leave count at a time',
    INVALID_DAYS:'Invalid leave days',  // 20240710 SS 756-5251
  },
  USERROLE: {
    REASSIGN_USERS: 'Users have been assigned to this user role. Kindly assign them to a different user role before the deactivation',
    ROLE_CREATED: 'User Role added successfully',
    ROLE_UPDATED: 'User Role updated successfully',
    CHANGES_AFFECT: 'All the changes will be affected to the added users. Are you sure you want to proceed?',
    USER_ASSIGNED: 'User has been assigned to the user role successfully',
    USERS_ASSIGNED: 'Users have been assigned to the user role successfully',
    ROLE_ACTIVATED: 'User Role activated successfully',
    ROLE_DEACTIVATED: 'User Role deactivated successfully',
    USERROLE_UPDATED: 'User\'s role updated successfully',
    USERSROLE_UPDATED: 'Users\' role updated successfully',
  },
  PAYROLL: {
    REQUIRED: 'is required',
    NUMBER_GREATER_THAN:'should be greater than',
    NUMBER_GREATER_THAN_OR_EQUAL:'should be greater than or equal to',
    NUMBER_LESS_THAN:'should be less than or equal to',
    CHAR_LENGTH_LESS_THAN:'length should be less than or equal to',
    NUMBER_VALID_WITH_TWO_DECIMAL: 'should be a valid number with up to two decimal places',
    FREEZE_WARNING: 'Are you sure you want to freeze the {{month}} payroll?', // freeze payroll warning message
    UNFREEZE_WARNING: 'Are you sure you want to unfreeze the {{month}} payroll?', // unfreeze payroll warning message
  },
};
