/**
 * 20200416 RP 118-4788 fixed BA concerns
 * 20200410 RP 122-4803 add the component is not null check part
 * 20210105 PU 118-4788 added header comments
 * 20210105 PU 122-4803 added header comments
 */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

export interface CanDeactivateGuardService {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

/**
 * 20200411 RP 122-4803 add the component is not null check part
 */
@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanDeactivateGuardService> {
    route: ActivatedRouteSnapshot;

    canDeactivate(component: CanDeactivateGuardService) {
        if (component !== null) {
            return component.canDeactivate ? component.canDeactivate() : true;
        }
        return true;
    }
}
