/**
 * 20201008 GC 190-4871 - added for disabled dahsboard
 * 20201118 GC 190-4871 - Added authentication service
 * 20201124 GC 190-4871 - Added bootstrap modal to view images, isYoutubeLink
 * 20210203 CV 191-4873 Addessed QA concerns.
 * 20210106 GC 189-4864 fixed prod build issues
 * 20210122 PU 249-4903 - changed error string to Error object.
 * 20210219 PU 291-4911 added common error handling
 * 20210219 RP 228-4892 Remove the cmpCode from API requests
 * 20231215 SS XENOV2-425 error notification without routing to a page
 */
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../authentication/authentication.service';
import { CoreApiService } from '../../services/api.service';
import { User } from 'src/app/core/models/user';
import { Router} from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationUtils} from '../../util/notification-util';
import { ErrorPageService} from '../../services/error-page.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  cmpCode;
  landingPageDetails;
  videos;
  images;
  docs;
  instructions;
  guide;
  currentUser: User;
  showModal: boolean;
  selectedIndex;

  constructor(
    private authenticationService: AuthenticationService,
    private apiService: CoreApiService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private notificationUtils: NotificationUtils,
    private errorService: ErrorPageService
  ) {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x; // Get current user
     });
  }

  ngOnInit() {
    this.cmpCode = localStorage.getItem('cmpCode') ? localStorage.getItem('cmpCode') : 0;
    this.getLandingPageConfig();
    this.selectedIndex = sessionStorage.removeItem('lastNavigatedIndex');
  }

 /**
  * show event for image viewer
  */
  show() {
    this.showModal = true; // Show-Hide Modal Check
  }
  /**
   * hide image viewer
   */
  hide() {
    this.showModal = false;
  }

  /**
   * Check whether video url is youtube
   */
  isYoutubeLink(url) {
    return url.includes('youtube.com'); // if contains youtube.com, return true
  }


  /**
   * Get landing page configurations and assign those to panel arrays
   * 228-4892-RP remove the cmpCode from the request
   */
  getLandingPageConfig() {
    this.apiService.getLandingPageConfig().subscribe(
      (response) => {
        if (response.httpStatusCode === 200 ) {
          this.landingPageDetails = response.data;
          // Assign resources to arrays
          this.videos = this.landingPageDetails.filter(item => ( item.panelName === 'video')); //  for videos
          this.images = this.landingPageDetails.filter(item => ( item.panelName === 'image')); //  for images
          this.docs = this.landingPageDetails.filter(item => ( item.panelName === 'doc')); //  for text files
          this.instructions = this.landingPageDetails.filter(item => ( item.panelName === 'text')); //  for instructions

          this.videos.map((obj) => {
            obj.trustedUrl =this.sanitizer.bypassSecurityTrustResourceUrl(obj.value); // security trusted url for youtube
            return obj;
          });
          // Sort arrays in decending order of sort order
          this.videos.sort((a, b) => b.sort - a.sort);
          this.images.sort((a, b) => b.sort - a.sort);
          this.docs.sort((a, b) => b.sort - a.sort);
          this.instructions.sort((a, b) => b.sort - a.sort);
        }
      },
      (error) => {
        this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
      });
  }

  /**
   * return true if there is no permission
   */
  isDisable(permission) {
    if (this.currentUser && this.currentUser.permissions.indexOf(permission) !== -1) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * Navigate if only have permision to the route
   */
  handleNavigation(permision, path) {
    if (this.currentUser.permissions.indexOf(permision) !== -1) {
      this.router.navigate([path]);
    } else {
      return false;
    }
  }


}
