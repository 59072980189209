/**
 * 20210201 RP 253-4908 add the country class to define the country dto
 * 20231115 SS 447-5171 alignment to t:COUNTRY changes
 */
export class Country {
  countryName: string;
  countrySymbol: string;
  phoneCode: string;
  countryCode: number;
}

export const COUNTRIES: Country[] = [
    {
      countryName: 'Sri Lanka',
      countrySymbol: 'LK',
      phoneCode: '+94',
      countryCode: 144
    },
    // {
    //   countryName: 'Canada',
    //   countrySymbol: 'CA',
    //   phoneCode: '+1',
    //   countryCode: 144
    // },
    // {
    //   countryName: 'Russian Federation',
    //   countrySymbol: 'RU',
    //   phoneCode: '+7',
    //   countryCode: 144
    // },
    // {
    //   countryName: 'United States of America',
    //   countrySymbol: 'US',
    //   phoneCode: '+1',
    //   countryCode: 144
    // },
    // {
    //   countryName: 'Japan',
    //   countrySymbol: 'JP',
    //   phoneCode: '+81',
    //   countryCode: 144
    // }
    //TODO rest of country details need to add this
  ];
