/**
 * 20201014 GC 173-4850 redirect disabled dashboard if user dont have permissions
 * 20201119 GC 190-4871 change guest dashboard to landing page
 */
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { AuthGuardService } from './services/auth.guard.service';
import { MainMenuSelectService } from '../services/main-menu-select.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private authGuardService: AuthGuardService,
        private mainMenuService: MainMenuSelectService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // check if route is restricted by permission
            if (route.data.permission) {
                const canAccess = this.authGuardService.canAccess(route.data.permission, currentUser.id);
                if (canAccess) {
                    return canAccess;
                } else {
                    // role not authorised so redirect to home page
                    this.mainMenuService.setCurrentIndex(2);
                    this.router.navigate(['/']);
                    return false;
                }
            } else {
                // authorised so return true
                return true;
            }
        }
        //not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
