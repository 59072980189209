/**
 * 20201011 CV 126-4845 Create this class to define the rest end points 
 * 20201023 173-4850 GC added comments
 * 20231122 SS XENOV2-434 5169 Added createUserPassword
 */
export const ENDPOINTS = {
    updatePassword: '/auth/password', // end pint for update password
    createUserPassword: '/auth/createUserPassword', // end pint for update password
    getEmail: '/auth/verification?token=${token}', // end point for get email
    getCredentials: '/auth/password' // Get initial end point
};
