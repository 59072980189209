/*
* 20200410 RP 122-4803 add the add contact permission to User array
* 20200506 PU 127-4804 added cmpCode in authenticate function
* 20201008 CV 202-4866 Added permission to all, view, add departments
* 20201014 CV 203-4872 Added permission to all, view, add sub departments
* 20201011 RP 126-4845 the organization related permissions
* 20201021 CV 204-4875 Added permission for organization - section
* 20201027 CV 202-4866 Added header comments.
* 20201113 GC 170-4843 Merged latest permissions to the issue
* 20210105 PU 127-4804 added header comments
* 20210319 GC 239-4902 Added view report permission
* 20210203 GC 213-4870 Add EDIT_BANK_DETAILS
* 20210408 GC 242-4906 Added new permissions for turnover report
*/
import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, materialize, dematerialize } from 'rxjs/operators';
import { User } from '../models/user';
import { Role } from '../models/role';

const users: User[] = [
    { id: 1, cmpCode: 1, username: 'admin', password: 'admin', firstName: 'Admin', lastName: 'User', role: Role.Admin, permissions: ["VIEW_DASHBOARD", "VIEW_EMPLOYEE", "VIEW_PAYROLL","VIEW_ORGANIZATION", "VIEW_EMPLOYEE_ALL", "VIEW_EMPLOYEE_ADD", "VIEW_EMPLOYEE_ADD_BUTTON", "VIEW_EMPLOYEE_EDIT", "VIEW_BASIC_DETAILS", "VIEW_PERSONAL_DETAILS", "VIEW_CONTACT_DETAILS", "VIEW_BANK_DETAILS", "ORG_GENERAL_SETUP", "ORG_COMPANY_DETAILS", "ORG_EMPLOYEE_SETUP", "ORG_EMPLOYEE_NUMBER", "ORG_DEPARTMENT","ORG_VIEW_SECTIONS", "ORG_ADD_SECTION", "ORG_VIEW_SECTION", "ORG_SUB_DEPARTMENT", "ORG_ADD_DEPARTMENT", "ORG_VIEW_DEPARTMENTS", "ORG_VIEW_DEPARTMENT", "ORG_SECTION", "ORG_EMP_CATEGORY", "ORG_DESIGNATION","ORG_VIEW_SUB_DEPARTMENTS","ORG_ADD_SUB_DEPARTMENT","ORG_VIEW_SUB_DEPARTMENT", "ORG_EMP_ACT", "ORG_EMP_SETTINGS", "ORG_TIME_SETUP", "ORG_TIME_GENARAL", "ORG_PAY_PERIOD", "ORG_SHIFTS", "ORG_ROSTERS", "ORG_OT", "ORG_LATE_MINUTES", "ORG_PAYROLL_SETUP", "ORG_USER_ACC_CONTROL", "ORG_APPRAISAL_SETUP", "VIEW_USER_MANAGEMENT", "EDIT_BASIC_DETAILS", "EDIT_PERSONAL_DETAILS", "EDIT_CONTACT_DETAILS",  "VIEW_USER_MANAGEMENT", "ORG_GENERAL_SETUP", "ORG_COMPANY_DETAILS", "ORG_EMPLOYEE_SETUP", "ORG_EMPLOYEE_NUMBER", "ORG_DEPARTMENT", "ORG_SUB_DEPARTMENT", "ORG_SECTION", "ORG_EMP_CATEGORY", "ORG_DESIGNATION", "ORG_EMP_ACT", "ORG_EMP_SETTINGS", "ORG_TIME_SETUP", "ORG_TIME_GENARAL", "ORG_PAY_PERIOD", "ORG_SHIFTS", "ORG_ROSTERS", "ORG_OT", "ORG_LATE_MINUTES", "ORG_PAYROLL_SETUP", "ORG_USER_ACC_CONTROL", "ORG_APPRAISAL_SETUP","INITIAL_SETUP", "ORG_EDIT_DESIGNATION", "ORG_VIEW_DESIGNATIONS", "ORG_VIEW_DESIGNATION", "ORG_ADD_DESIGNATION", "VIEW_REPORT", "VIEW_EMPLOYEE_REPORT", "VIEW_EMPLOYEE_TURNOVER_REPORT"] },
    { id: 2, cmpCode: 1,username: 'user', password: 'user', firstName: 'Normal', lastName: 'User', role: Role.User, permissions: ["VIEW_DASHBOARD", "VIEW_EMPLOYEE", "VIEW_EMPLOYEE_ALL", "VIEW_ORGANIZATION"] }
];

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { url, method, headers, body } = request;

        // wrap in delayed observable to simulate server api call
        return of(null)
            .pipe(mergeMap(handleRoute))
            .pipe(materialize())
            .pipe(delay(500))
            .pipe(dematerialize());

        function handleRoute() {
            switch (true) {
                case url.endsWith('/users/authenticate') && method === 'POST':
                    return authenticate();
                case url.endsWith('/users') && method === 'GET':
                    return getUsers();
                case url.match(/\/users\/\d+$/) && method === 'GET':
                    return getUserById();
                default:
                    // pass through any requests not handled above
                    return next.handle(request);
            }

        }

        // route functions

        function authenticate() {
            const { username, password } = body;
            const user = users.find(x => x.username === username && x.password === password);
            if (!user) return error('Username or password is incorrect');
            return ok({
                id: user.id,
                username: user.username,
                firstName: user.firstName,
                lastName: user.lastName,
                cmpCode: user.cmpCode,
                role: user.role,
                token: `fake-jwt-token.${user.id}`,
                permissions: user.permissions
            });
        }

        function getUsers() {
            if (!isAdmin()) return unauthorized();
            return ok(users);
        }

        function getUserById() {
            if (!isLoggedIn()) return unauthorized();

            // only admins can access other user records
            if (!isAdmin() && currentUser().id !== idFromUrl()) return unauthorized();

            const user = users.find(x => x.id === idFromUrl());
            return ok(user);
        }

        // helper functions

        function ok(body) {
            return of(new HttpResponse({ status: 200, body }));
        }

        function unauthorized() {
            return throwError({ status: 401, error: { message: 'unauthorized' } });
        }

        function error(message) {
            return throwError({ status: 400, error: { message } });
        }

        function isLoggedIn() {
            const authHeader = headers.get('Authorization') || '';
            return authHeader.startsWith('Bearer fake-jwt-token');
        }

        function isAdmin() {
            return isLoggedIn() && currentUser().role === Role.Admin;
        }

        function currentUser() {
            if (!isLoggedIn()) return;
            const id = parseInt(headers.get('Authorization').split('.')[1]);
            return users.find(x => x.id === id);
        }

        function idFromUrl() {
            const urlParts = url.split('/');
            return parseInt(urlParts[urlParts.length - 1]);
        }
    }
}

export let fakeBackendProvider = {
    // use fake backend in place of Http service for backend-less development
    provide: HTTP_INTERCEPTORS,
    useClass: FakeBackendInterceptor,
    multi: true
};
