/**
 * 20201013 GC 173-4850 added initial login error page service
 * 20210120 GC 189-4864 Added comments
 */
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ErrorPageService {

    private errorMessage = '';
    lastAttemptedUrl;
    constructor() { }
 // get error message
    getError() {
        return this.errorMessage;
    }
// set error message
    setError(error) {
        this.errorMessage = error;
    }
    setLastUrl(url){
      this.lastAttemptedUrl = url;
    }

    getLastUrl(){
      return this.lastAttemptedUrl;
    }

}
