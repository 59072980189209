/**
 * 20201011 CV 126-4845 This is a component class for the organization initial setup
 * 20201011 RP 126-4845 add the methods for the get country details
 * 20201021 RP 126-4845 remove the navigation confirmation page and add inline massage
 * 20201022 RP 126-4845 add the afterviewInit method to pint cursor to company name
 * 20210119 PU 245-4897 called common email validator on validEmail() onchange event
 * 20210122 PU 249-4903 changed error string to Error object.
 * 20210219 PU 291-4911 added common error handling
 * 20210202 RP 253-4908 remove the calling seb service to get the country details and update it to get the country details from the country service
 * 20211005 GC 791-4964 moved messages to common util
 * 20231115 SS 447-5171 alignment to t:COUNTRY changes
 * 20231215 SS XENOV2-425 error notification without routing to a page
 */

import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialog, MatSelect } from '@angular/material';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import { NotificationUtils } from '../../../../core/util/notification-util';
import { SignUp } from '../../dto/sign-up';
import { Router, ActivatedRoute } from '@angular/router';
import { OrgApiService } from '../../services/org-api-service';
import { take, takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { CommonUtils } from '../../../../core/util/common-utils';
import {EmailValidatorService} from '../../../../core/services/email-validator.service';
import {ErrorPageService} from '../../../../core/services/error-page.service';
import { StatDataService } from 'src/app/modules/organization/services/static-data.service';
import { Messages } from '../../../../core/util/common-messages';
import { AllCountry } from '../../dto/all-country';
@Component({
  selector: 'company-signup',
  templateUrl: './organization-initial-setup.component.html',
  styleUrls: ['./organization-initial-setup.component.css']
})
export class OrganizationInitialSetupComponent implements OnInit, OnDestroy, AfterViewInit {
  initialSetupForm: FormGroup; // intialize the form group
  countryList: any = null; // variable for the country list
  phoneNumber: string = null; // variable for the phone number
  countries = [];
  selectedCountry;
  isSuccesSignUp: boolean = false; // variable to identify the process succes or not
  isPendingResponse: boolean = false; // variable to disabled untill recieve the response
  countryConfig = {
  displayKey: 'countryName',
  search: true,
  height: 'auto',
  placeholder: 'Select' ,
  limitTo: 0 ,
  moreText: 'more' ,
  noResultsFound: 'No results found!' ,
  searchPlaceholder: 'Search Your country',
  searchOnKey: 'countryName' ,
  clearOnSelection: false ,
  inputDirection: 'ltr'
  };
  messages = Messages;
  public countryFilter = new FormControl();
  public filteredCountries: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();

  //add this to focus the cursor for the company name
  @ViewChild('compNameInput' , { static: true }) compNameInput: ElementRef;
  @ViewChild('fullNameInput' , { static: true }) fullNameInput: ElementRef;
  @ViewChild('eMailInput' , { static: true }) eMailInput: ElementRef;
  @ViewChild('telephoneInput' , { static: true }) telephoneInput: ElementRef;


  //form directive for the sign up form
  @ViewChild('formDirective', { static: true }) signUpForm: FormGroupDirective;
  constructor(
      public dialog: MatDialog,
      public apiService: OrgApiService,
      private notificationUtils: NotificationUtils,
      public router: Router,
      private route: ActivatedRoute,
      private emailValidatorService: EmailValidatorService,
      private commonUtils: CommonUtils,
      private errorService: ErrorPageService,
      private statDataService: StatDataService
    ) { }

    // intialize the form
    initialSetupFormValidation() {
      this.initialSetupForm = new FormGroup({
        companyName: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(150)])), // set comapny name
        companyWeb: new FormControl('', Validators.maxLength(253)), // set company web name
        fullName: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(250)])), // set full name
        email: new FormControl('', Validators.required), // set user email address
        country: new FormControl(0, Validators.required), // set country to default integer
        telephone: new FormControl('', Validators.compose([Validators.required,
           Validators.pattern(/^(((\+){1}[0-9]{1,}))$/), Validators.maxLength(16)])) // set phonenumber
      });
    }

  // call this method when loading the page
  ngOnInit() {
    this.initialSetupFormValidation(); // intialize the form
    this.getCountries(); // call the country api and fetched country details

    // this is filter to get country with user inputs
    this.countryFilter.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterCountries();
    });

  }

  //the method will run after the page loading
  ngAfterViewInit(){
    setTimeout(() => {
      this.compNameInput.nativeElement.focus();
   }, 0)
  }

  // country filter method
  filterCountries() {
    if (!this.countryList) {
      return;
    }
    // get the search keyword
    let search = this.countryFilter.value;
    if (!search) {
      this.filteredCountries.next(this.countryList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the countries
    this.filteredCountries.next(
      this.countryList.filter(country => country.countryName.toLowerCase().indexOf(search) > -1)
    );
  }

  // use this method to show the error massages
  hasInitialSetupFormError(controlName: string, errorName: string) {
    return this.initialSetupForm.controls[controlName].hasError(errorName);
  }

  // service to call the country list
  getCountries(){
    // this.countryList = this.statDataService.getCountries(); // get the country details from the Countries list
    this.apiService.getCountries().subscribe(
      (response) => {
        const countries: AllCountry[] = [];
        response.data.forEach(country => {
          const cntry = new AllCountry;
          cntry.setCountryCode(country.code);
          cntry.setCountryName(country.name);
          cntry.setCountrySymbol(country.alpha2);
          cntry.setPhoneCode(country.dialCode);
          countries.push(cntry);
        });
        console.log(response);
        console.log(countries);
        this.countryList = countries;
        this.getClientCountryDetails(); // call client location detail api to get the client country
        this.filteredCountries.next(this.countryList.slice());
      });
  }

  // service to get the country details
  getClientCountryDetails(){
    this.apiService.getIpDetails().subscribe(
      (response) => {
        const country = this.countryList.filter(country => country.countrySymbol === response.country_code2);
        // check countrySymbol is match with geolocation api country code
        if (country !== undefined || country.length !== 0) {
          this.initialSetupForm.get('country').setValue(country[0].countryCode); // set the country code
          this.phoneNumber = country[0].phoneCode;
          this.initialSetupForm.get('telephone').setValue(this.phoneNumber); // set the phone number value
        } else {
          // set the values if country does not exist for the request
          this.initialSetupForm.get('country').setValue(144);
          this.initialSetupForm.get('telephone').setValue('+1');
        }
      },
      (error) => {
        // set the values if error occured
        this.initialSetupForm.get('country').setValue(144);
        this.initialSetupForm.get('telephone').setValue('+1');
      });
  }

  // change the phone number with country selection
  onCountrySelection(event) {
    const coun = this.countryList.filter(country => (event.value === country.countryCode));
     // check select country code with existing country array
    this.phoneNumber = coun[0].phoneCode;
    this.initialSetupForm.get('telephone').setValue(this.phoneNumber); // set the phone number
  }

  // set user entered details in the save basic details object
  setUserInputsOject(signUpForm: FormGroup): SignUp {
    const signUp = new SignUp(); // create a new sign up method
    signUp.setCompName(signUpForm.get('companyName').value);
    signUp.setCompWeb(signUpForm.get('companyWeb').value);
    signUp.setCountryCode(signUpForm.get('country').value);
    signUp.setEmail(signUpForm.get('email').value);
    signUp.setFullName(signUpForm.get('fullName').value);
    signUp.setPhoneNumber(signUpForm.get('telephone').value);
    return signUp;
  }

  // pass the sign up request into the back end
  saveSignUpDetails(){
    this.chekFormExistEmptyOrSpace();
    const telephone = this.countryList.filter(country => (this.initialSetupForm.get('telephone').value === country.phoneCode));
    if(telephone[0]){
       this.initialSetupForm.get('telephone').setErrors({required: true});
     }

    if(this.initialSetupForm.invalid){
        if(this.initialSetupForm.get('fullName').invalid){
          this.fullNameInput.nativeElement.focus();
          return;
        }
        if(this.initialSetupForm.get('email').invalid){
          this.eMailInput.nativeElement.focus();
          return;
        }

        if(this.initialSetupForm.get('telephone').invalid){
          this.telephoneInput.nativeElement.focus();
          return;
        }
        return;
    }

    this.isPendingResponse = true; //set the sign up button as disabled until response receive
    const signUp: SignUp  = this.setUserInputsOject(this.initialSetupForm);
    this.apiService.saveSignUp(signUp).subscribe(
      (response) => {
        if (response.httpStatusCode === 201) {
          this.isSuccesSignUp = true;
        }
      },
      (error) => {
        this.isPendingResponse = false;
        if (error.errorCode === 409) {
          this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
        } else if (!(error.errorCode === 400 || error.errorCode === 404)) {
          this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
        }
      }
    );
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  //check the empty string of the input fields
  chekFormExistEmptyOrSpace(): boolean{
      if(this.commonUtils.isEmptyOrSpaces(this.initialSetupForm.get('companyName').value)){
         this.initialSetupForm.get('companyName').setErrors({required: true});
      }
      if(this.commonUtils.isDefaultInt(this.initialSetupForm.get('country').value)){
        this.initialSetupForm.get('country').setErrors({required: true});
      }
      if(this.commonUtils.isEmptyOrSpaces(this.initialSetupForm.get('email').value)){
        this.initialSetupForm.get('email').setErrors({required: true});
      }
      if(this.commonUtils.isEmptyOrSpaces(this.initialSetupForm.get('fullName').value)){
        this.initialSetupForm.get('fullName').setErrors({required: true});
      }
      if(this.commonUtils.isEmptyOrSpaces(this.initialSetupForm.get('telephone').value)){
        this.initialSetupForm.get('telephone').setErrors({required: true});
      }
      console.log(this.hasInitialSetupFormError('country', 'required'));
      console.log(this.commonUtils.isDefaultInt(this.initialSetupForm.get('country').value));
      console.log(this.initialSetupForm.get('country').value);
      return false;
  }

  //validate the company web
  changeCompWeb(){
    //signUpForm.get('companyWeb').value
    let value = this.initialSetupForm.get('companyWeb').value;
    let startWith = value.match('^[a-zA-Z0-9].*'); //check start with alpha numeric value
    let dotExist = value.match('[\.]'); // check exist at least one '.' exist
    let otherCharsExist = value.match('[^a-zA-Z0-9\.\-]'); // check other charcters exist
    let endWith = value.match('.*[a-zA-Z0-9]$'); // check ends with alpha numeric value
    let togetherWith = value.match('[\.]+[\-]|[\-]+[\.]|[\-]+[\-]|[\.]+[\.]'); //check . and - are in together
    if(value.length>253){
      this.initialSetupForm.get('companyWeb').setErrors({maxlength: true});
    }else if(value && (!startWith || !dotExist || otherCharsExist || !endWith || togetherWith)){
      this.initialSetupForm.get('companyWeb').setErrors({pattern: true});
    }

    }

  validEmail() {
    const value = this.initialSetupForm.controls.email.value;
    if (value != null && value !== '' && !this.emailValidatorService.validate(value)) {
      this.initialSetupForm.get('email').setErrors({pattern: true});
      return true;
    }
    if (value.length > 100) {
      this.initialSetupForm.get('email').setErrors({maxlength: true});
      return true;
    }
  }
}


