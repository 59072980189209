import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AuthGuardService {
    private perms: string[] = null;

    constructor() { }

    public canAccess(permission: string, userId: number) {
        // get all the permissions
        this.perms = localStorage.getItem('permissions').split(',');
        if (undefined == this.perms) {
            this.perms = [''];
        }
        let canAccess = false;
        if (this.perms.find(a => a === permission)) {
            canAccess = true;
        }
        return canAccess;
    }
}