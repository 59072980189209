/**
 * 20210202 RP 253-4908 add this service to get the country details
 * 20210707 RP 493-4937 added the service to get shift types
 */
import { Injectable } from '@angular/core';
import { COUNTRIES, Country } from '../../../util/org-static-data/country';
import { ShiftType, SHIFTTYPES } from '../../../util/org-static-data/shift-type';

@Injectable()
export class StatDataService {

  constructor() { }

  /**
   * get the all country details with ascending order by country name
   */
  getCountries(): Country[] {
    return COUNTRIES.sort( function(a, b) {
        if (a.countryName < b.countryName) { return -1; }
        if (a.countryName > b.countryName) { return 1; }
        return 0;
    });
  }

  /**
   * get the all country details with ascending order by country name
   */
   getShiftTypes(): ShiftType[] {
    return SHIFTTYPES;
  }
}
