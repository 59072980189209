/**
 * 20210126 CV 208-4891 created this class for the Rest services 
 */
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { ENDPOINTS } from '../util/api-endpoints';
import { appConfig } from 'src/environments/environment';

@Injectable()
export class ApiService {

    constructor(private httpService: HttpService) {
    }

    // call Update password API
    sendPasswordResetLink(body: any) {
        const url = appConfig.appRoot.concat(ENDPOINTS.sendPasswordResetLink);
        return this.httpService.httpPost(url, body, {observe : 'response'});
    }

    checkResetLink(body: any) {
        const url = appConfig.appRoot.concat(ENDPOINTS.checkResetLink);
        return this.httpService.httpPost(url, body, {observe : 'response'});
    }

    resetUserPassword(body: any) {
        const url = appConfig.appRoot.concat(ENDPOINTS.resetUserPassword);
        return this.httpService.httpPut(url, body, {observe : 'response'});
    }

    sendLogonPasswordResetLink(body: any) {
        const url = appConfig.appRoot.concat(ENDPOINTS.sendLogonPasswordResetLink);
        return this.httpService.httpPost(url, body, {observe : 'response'});
    }

    checkLogonResetLink(body: any) {
        const url = appConfig.appRoot.concat(ENDPOINTS.checkLogonResetLink);
        return this.httpService.httpPost(url, body, {observe : 'response'});
    }

    resetLogonPassword(body: any) {
        const url = appConfig.appRoot.concat(ENDPOINTS.resetLogonPassword);
        return this.httpService.httpPut(url, body, {observe : 'response'});
    }

}