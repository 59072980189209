/**
 * 20210201 CV 206-4880 Added to block special characters
 * 20201103 122-4803 CV Added a new method called specialCharacterSetThree(event) to allow only specific character set.
 * 20201117 GC 118-4788 AddedspecialCharacterSetDefault
 * 20201211 118-4788 CV Added methods to paste special characters.
 * 20210101 226-4885 CV Added methods to avoid paste on special characters.
 * 20210120 248 CV Added method to add special characters for company bank details.
 * 20210326 GC 206-4880 changed special charactor restrict method
 * 20231107 SS XENOV2-434 5169 Added plusmarksNumericOnly and vlidateOnPasteNumericPlusMarkOnly functions
 * 20240725 SS 753-5260 Added to core service and used as a provider service via shared modules to reduced redundancy
 * 20240725 SS 753-5260 Added allowPhoneNumberOnly method which is used but never created in company genaral details
 */
import { Injectable } from '@angular/core';
@Injectable()
export class specialCharService {
   constructor() { }
  
   specialCharacterSetOne(event) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore, dash, colon, semi-colon, ful stop, slash, hash
    if (/[a-zA-Z0-9-_#/:;. ]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  specialCharacterSetDefault(event) {

    const inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore, dash, colon, semi-colon, ful stop, slash
    if (/[a-zA-Z0-9-_/:;. ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  specialCharacterSetTwo(event){
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore, dash, colon, semi-colon, ful stop and comma
    if (/[a-zA-Z0-9-_:;., ]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  specialCharacterSetThree(event){
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore, dash, colon, semi-colon, ful stop and comma
    if (/[a-zA-Z0-9-_:;.,() ]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }
  
  allowOnlyAlphanumeric(event){
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  specialCharacterSetfour(event){
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore, dash, colon, semi-colon, ful stop and comma
    if (/[a-zA-Z0-9-_:;., ()&]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  specialCharacterSetfive(event){
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore, dash, slash colon, semi-colon, ful stop and comma
    if (/[a-zA-Z0-9-_:;.,/() ]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  plusmarksNumericOnly(event){
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, plus
    if (/[0-9+]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  plusmarksOnly(event){
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, plus
    if (/[a-zA-Z0-9+]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  allowCharactersForCurrency(event){
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, comma, full stop
    if (/[a-zA-Z0-9,.]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  specialCharacterSetSix(event){
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore, dash, colon, semi-colon, ful stop and comma
    if (/[a-zA-Z0-9-_:;.,()& ]/.test(inp)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  

  specialCharacterSetDefaultPhaste(event) {

    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    // Allow numbers, alpahbets, space, underscore, dash, colon, semi-colon, ful stop, slash
    if (/^[a-zA-Z0-9-_/:;. ]/.test(pastedText)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

 
  
  vlidateOnPasteSetOne(event) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    if (/^[a-zA-Z0-9-_#/:;. ]*$/.test(pastedText)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  vlidateOnPasteSetTwo(event) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    if (/^[a-zA-Z0-9-_:;., ]*$/.test(pastedText)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  vlidateOnPasteCurrency(event){
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    if (/^[a-zA-Z0-9,.]*$/.test(pastedText)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  vlidateOnPastePlusMarkOnly(event){
    let clipboardData = event.clipboardData;
      let pastedText = clipboardData.getData('text');
      if (/^[a-zA-Z0-9+]*$/.test(pastedText)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
  }

  vlidateOnPasteNumericPlusMarkOnly(event){
    let clipboardData = event.clipboardData;
      let pastedText = clipboardData.getData('text');
      if (/^[0-9+]*$/.test(pastedText)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
  }

  vlidateOnPasteAllowOnlyAlphanumeric(event){
    let clipboardData = event.clipboardData;
      let pastedText = clipboardData.getData('text');
      if (/^[a-zA-Z0-9]*$/.test(pastedText)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
  }

  vlidateOnPasteSpecialCharacterSetThree(event){
    let clipboardData = event.clipboardData;
      let pastedText = clipboardData.getData('text');
      if (/^[a-zA-Z0-9-_:;.,() ]*$/.test(pastedText)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
  }

  specialCharacterSetfourPhaste(event){
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    // Allow numbers, alpahbets, space, underscore, dash, colon, semi-colon, ful stop and comma
    if (/^[a-zA-Z0-9-_:;., ()&]/.test(pastedText)) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  vlidateOnPasteSpecialCharacterSetFive(event){
    let clipboardData = event.clipboardData;
      let pastedText = clipboardData.getData('text');
      if (/^[a-zA-Z0-9-_:/;.,() ]*$/.test(pastedText)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
  }

  vlidateOnPasteSetSix(event) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    if (/^[a-zA-Z0-9-_:;.,()& ]*$/.test(pastedText)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  allowPhoneNumberOnly(event) {
    const inp = String.fromCharCode(event.keyCode);
    // Allow numbers and plus
    if (/[0-9+]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

}