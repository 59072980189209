/**
 * 20240401 SV XENOV2-638 MyLeave dashboard
 * 20240422 SV XENOV2-638 MyLeave dashboard summary chart
 * 20240829 SV XENOV2-695 Dashboard Enhancement Frontend and UI 
 */

import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatCalendar, MatDialogRef } from '@angular/material';
import { CoreApiService } from 'src/app/core/services/api.service';
import { NotificationUtils } from 'src/app/core/util/notification-util';

@Component({
  selector: 'app-summary-chart-modal',
  templateUrl: './summary-chart-modal.component.html',
  styleUrls: ['./summary-chart-modal.component.css']
})
export class SummaryChartModalComponent implements OnInit {

  empLeaveDays: any[];
  depCode: any;
  leaveDates = [];
  empName: string;
  focusedButton: number;
  filteredList: any[];

  @ViewChild('calendar', {static: false}) calendar: MatCalendar<Date>;
  
  constructor(
    private apiService: CoreApiService,
    private notificationUtils: NotificationUtils,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SummaryChartModalComponent>,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.depCode = this.data.depCode; 
    this.getEmpLeaveDaysInDep();
  }

  getEmpLeaveDaysInDep(){
    this.apiService.getEmpLeaveDaysInDep(this.depCode).subscribe(
      (response) => {
        if (response.httpStatusCode === 200 ) {
          this.empLeaveDays = response.data;
          this.filteredList = response.data;
          this.leaveDates = response.data.length > 0 ? response.data[0].leaveDateList : [];
          this.calendar.updateTodaysDate();
          this.focusedButton = 0;
        }
      },
      (error) => {
        if (error.httpStatusCode === 409 || !(error.httpStatusCode === 400 || error.httpStatusCode === 404)) {
          this.notificationUtils.showErrorMessage(error.errorMessage, error.data);
        }
      }
    );
  }

  // Define a function to return CSS class for highlighted dates
  dateClass = (date: Date): string => {
    let segment = this.getLeaveDateClass(date);
    switch (segment) {
      case 1:
        return "event morning";
      case 2:
        return "event evening";
      case 3:
        return "event fullday";
      case 4:
        return "event holiday";
      default:
        return "";
    }
  };

  getLeaveDateClass(date: Date): number {
    let segment = 0
    for (const element of this.leaveDates) {
      const updatedDate = new Date(element.date);
      if (this.isSameDate(updatedDate,date)) {
        segment = element.segment;
      }
    }
    return segment;
  }

  isSameDate(d1: Date, d2: Date): boolean {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  loadLeaveDates(emp: any){
    this.leaveDates = emp.leaveDateList;
    this.calendar.updateTodaysDate();
  }

  applyFilter() {
    console.log(this.empName);
    this.filteredList = this.empLeaveDays.filter((data) => {
      const dataEmpName = data.firstName + ' ' + data.lastName;
      return this.empName ? dataEmpName.trim().toLowerCase().includes(this.empName.trim().toLowerCase()) : true;
    });
    this.cdr.detectChanges();
    this.leaveDates = this.filteredList.length > 0 ? this.filteredList[0].leaveDateList : [];
    this.calendar.updateTodaysDate();
  }

  clearEmpName() {
    this.empName = undefined;
    this.applyFilter();
  }

  closeSammaryChartModal(){
    this.dialogRef.close();
  }

}
