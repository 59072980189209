import { Directive, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit {
    @Input('hasPermission') permissions: Array<string>;

    perms: string[];

    constructor(private elementRef: ElementRef,
        private renderer: Renderer2) { }

    ngOnInit() {
        this.setHasPermissions();
    }

    setHasPermissions() {
        this.perms = localStorage.getItem('permissions').split(',');
        if (undefined == this.perms) {
            this.perms = [""];
        }
        //Single permission
        if (this.permissions && this.permissions.length == 1) {
            if (this.perms.indexOf(this.permissions[0]) === -1) {
                this.renderer.removeChild(this.elementRef.nativeElement, this.elementRef.nativeElement);
            }
        }
        //Multiple permission
        if (this.permissions && this.permissions.length > 1) {
            if (!this.permissions.every(permission => this.perms.includes(permission))) {
                this.renderer.removeChild(this.elementRef.nativeElement, this.elementRef.nativeElement);
            }
        }
    }
}