/**
 * 20201011 RP 126-4845 add organization menu item
 * 20201113 GC 170-4843 Merge latest menu
 * 20200118 CV 173-4850 Refactor menu items.
 * 20210504 GC 242-4906 Added menu item for reports
 * 20231025 HP XENOV2-438-5167 : Leave Management UI development
 * 20231206 SS XENOV2-425 Temporary permissions change
 * 20231215 SS XENOV2-425 permission changes
 * 20240801 SS Removed 'INITIAL_SETUP', 'COMP_GENERAL_SETUP', 'VIEW_DASHBOARD'
 */
import { MenuBar } from './menu-bar';

export class MenuItems {

  get mainMenuItems(): MenuBar[] {
    return [
      {
        title: 'Dashboard',
        icon: 'lnr-home',
        routerLink: '/dashboard',
        menuIndex: 0,
        mainLink: 'dashboard',
        permissions: "XENO_DASHBOARD"
      },
      {
        title: 'Employees',
        icon: 'lnr-users',
        routerLink: '/employee',
        menuIndex: 1,
        mainLink: 'employee',
        permissions: "VIEW_EMPLOYEE"
      },
      {
        title: 'Organization',
        icon: 'lnr-briefcase',
        routerLink: '/organization',
        menuIndex: 3,
        mainLink: 'organization',
        permissions: "VIEW_COMPANY"
      },
      {
        title: 'Leave Management',
        icon: 'lnr-home',
        routerLink: '/lms',
        menuIndex: 0,
        mainLink: 'leavemanagement',
        permissions: "LEAVE_MODULE"
      },
    ]
  }
}
