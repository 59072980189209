/*
* 20200402 HA 119-4799 changed error message.
* 20200722 GC 167-4842 integration view designation, add designation.
* 20210105 PU 119-4799 added header comment.
* 20210120 GC 189-4864 Added comments
* 20210121 PU 249-4903 changed error string to Error object with error parameters.
* 20210202 CV 208-4891 Added new error intercepter
* 20210310 PU 291-4911 Excluded 401 from error propagation
* 20210511 GC 304-4914 removed refresh token
* 20210511 GC 246-4905 added logout functionality
* 20210512 SR 410-4911 Logout when expired token functionality added
*/
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap  } from 'rxjs/operators';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if ([401, 403].indexOf(err.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        //this.authenticationService.collectFailedRequest(request);
        this.authenticationService.logout();
      } else {
        const error: Error = {
          errorMessage: err.error.message || err.statusText,
          errorCode: err.error.httpStatusCode || err.status,
          apiStatusCode: err.error.apiStatusCode,
          data: err.error.data,
          additionalParams: err.error.additionalParams
        };
        return throwError(error);
      }
    }));
  }
}

export interface Error {
  errorMessage: string;
  errorCode: number;
  apiStatusCode: number;
  data: any;
  additionalParams: any;
}
