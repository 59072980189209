/*
  * 20240307 SV XENOV2-640 Added Terms and Conditions page and Privacy Policy page
*/

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
