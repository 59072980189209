/**
 * 20240116 SS 436-5195 Restricted undetected service modal to close on outiside click
 */

import { Injectable } from '@angular/core';
import { NotificationUtils } from '../util/notification-util';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from '../components/confirmation.component';

@Injectable()
export class UnsavedDetectService {
    constructor(private notificationUtils: NotificationUtils,
        private ngbModal: NgbModal) {
    }

    confirm(title?: string, message?: string, icon?: string, headerColor?: string) {
        const m = this.ngbModal.open(ConfirmationComponent, {
                backdrop : 'static',
                keyboard : false
            }
        );
        m.componentInstance.title = title ;
        m.componentInstance.confMessage = message || 'Is it OK?';
        m.componentInstance.icon = icon ;
        m.componentInstance.headerColor = headerColor ;
        return m.result;
    };
}