/**
 * 20210129 CV 208-4891 Added methods to handle forgot password link.
 * 20210208 CV 208-4891 Unsubscribed all subscriptions.
 * 20210319 GC 208-4891 Added enter key submit
 * 20210406 GC 189-4864 fix xeno-385 and 382 issues
 * 20210407 SR 208-4891 Focus to email text box and submit button behaviour changed.
 */
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { appConfig } from 'src/environments/environment';
@Component({
  selector: 'forgot-pwd',
  templateUrl: './do-forgotpwd.component.html',
  styleUrls: ['./do-forgotpwd.component.css']
})
export class DoForgotpwdComponent implements OnInit, AfterViewInit, OnDestroy {
  frmforgotpwd: FormGroup;
  forgotPwdEmailSubscription;
  isSuccesResetLink: boolean = false; // variable to identify the process succes or not
  portalName;
  logonToken;
  emailInputValue = ' ';


  @ViewChild('email', { static: true }) email: ElementRef;
  constructor(
    private apiService: ApiService,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,) { }

  ngOnInit() {
    this.getTokenFromUrl();
    if(!sessionStorage.getItem("logonToken")){
      this.logonToken = '';
    }else{
      this.logonToken = sessionStorage.getItem("logonToken");
    }
    
    this.frmforgotpwd = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(100)])),

    });
    
  }

  //the method will run after the page loading
  ngAfterViewInit() {
    this.email.nativeElement.value = '';
    // this.frmforgotpwd.controls.email.setErrors({ emailValError: false });
    setTimeout(() => {
      this.renderer.selectRootElement(this.email["nativeElement"]).focus();
    }, 1000)
  }


  //display error message in form control
  hasFieldError(controlName: string, errorName: string) {
    this.forgotPwdEmailSubscription = this.frmforgotpwd.get('email').valueChanges.subscribe(value => {
      const emailRegex = /^[\S]+@([\S]+\.)+[\S]+$/;
      if (value != null && value !== '' && !emailRegex.test(value)) {
        this.frmforgotpwd.controls.email.setErrors({ emailValError: true });
      }
    });
    return this.frmforgotpwd.controls[controlName].hasError(errorName);
  }

  validateFieldEmail(){
    this.emailInputValue = this.frmforgotpwd.get('email').value.trim();
    const emailRegex = /^[\S]+@([\S]+\.)+[\S]+$/;
      if (this.emailInputValue == null || this.emailInputValue == '' ) {
        this.frmforgotpwd.controls.email.setErrors({ emailValError: true });
        this.frmforgotpwd.controls['email'].hasError('required');
        return false;
      }else if(!emailRegex.test(this.emailInputValue)){
        this.frmforgotpwd.controls.email.setErrors({ emailValError: true });
        this.frmforgotpwd.controls['email'].hasError('emailValError');
        return false;
      }
      return true;
  }
  /**
 * check for query params and get token if exist.
 */
  getTokenFromUrl() {
    this.route.queryParams.subscribe(params => {
      this.logonToken = params['token'];

    });

  }

  sendResetPasswordLink() {
    if(this.validateFieldEmail()){
      let url = window.location.host;
      const host = appConfig.appDomain;
      var portalName = url.split('.'+host+'.'); // get string before .xeno.
      if (portalName[0] !=url) { // If portal name exist, do portal login
        this.sendPortalPasswordReset();
      } else {
        this.sendLogonPasswordReset();
      }
    }
  }

  //send portal password reset link
  sendPortalPasswordReset() {
    let url = window.location.host;
    const host = appConfig.appDomain;
    var portalName = url.split('.'+host+'.'); // get string before .xeno.
    const passwordResetData = { // create object with available data

      email: this.frmforgotpwd.get('email').value,
      portalName: portalName[0]
    };
    this.apiService.sendPasswordResetLink(passwordResetData).subscribe( // send to API
      (response) => {
        if (response.body.apiStatusCode === 0) {
          this.isSuccesResetLink = true;
          this.frmforgotpwd.controls['email'].disable();
        }

      },
      (error) => {
        if (error.apiStatusCode === 7) { // invalid email
          this.frmforgotpwd.controls.email.setErrors({ alreadExistError: true });
        } else if (error.apiStatusCode === 18) { // If the account is locked
          this.frmforgotpwd.controls.email.setErrors({ lockedAccError: true });
        } else if (error.apiStatusCode === 19) { //if the account is not actived
          this.frmforgotpwd.controls.email.setErrors({ inactiveAccError: true });
        }else if (error.apiStatusCode === 5){
          this.frmforgotpwd.controls.email.setErrors({ invalidRequest: true });
        }

      }
    )
  }

  //send logon password reset link
  sendLogonPasswordReset() {
    const logonPasswordResetData = { // create object with available data

      email: this.frmforgotpwd.get('email').value,
      token: sessionStorage.getItem("logonToken")
    };
    this.apiService.sendLogonPasswordResetLink(logonPasswordResetData).subscribe( // send to API
      (response) => {
        if (response.body.apiStatusCode === 0) {
          this.isSuccesResetLink = true;
          this.frmforgotpwd.controls['email'].disable();
        }

      },
      (error) => {
        if (error.apiStatusCode === 7) { // invalid email
          this.frmforgotpwd.controls.email.setErrors({ alreadExistError: true });
        }else if (error.apiStatusCode === 18 && error.errorMessage ==='Account is locked') { // If the account is locked
          this.frmforgotpwd.controls.email.setErrors({ lockedAccError: true });
        }else if (error.apiStatusCode === 18 && error.errorMessage ==='Invalid request. Password has not been set yet') { // If the account is locked
          this.frmforgotpwd.controls.email.setErrors({ passwordNotSetError: true });
        }else if (error.apiStatusCode === 19) { //if the account is not actived
          this.frmforgotpwd.controls.email.setErrors({ inactiveAccError: true });
        }else if (error.apiStatusCode === 5){
          this.frmforgotpwd.controls.email.setErrors({ invalidRequest: true });
        }

      }
    )
  }

   //submit the form when the user hits the enter button in the last form field.
   keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.sendResetPasswordLink();
    }
  }

  /**
   * Clear success message
   */
  clearMsg(){
    this.isSuccesResetLink = false;
  }
  //destroy the subscription in ondestroy method
  ngOnDestroy() {
    if (this.forgotPwdEmailSubscription) {
      this.forgotPwdEmailSubscription.unsubscribe();
    }
  }
}
