/**
 * 20201008 GC 190-4871 added "guestUser" to handle users doesnt agreed T & C
 * 20201013 GC 173-4850 Added setGuestMenu() for disabled dashboard
 * 20201021 GC 168-4847  added comments
 * 20201130 GC 190-4871 added all menu items to display (removed permissions)
 * 20210203 CV 191-4873 Addessed QA concerns.
 * 20231215 SS XENOV2-425 checking permissions
 * 20231219 SS XENOV2-425 Minor changes on the structure of the main menu component
 * 20240216 SS XENOV2-532 Main Layout toggle class issue fix
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { MainMenuItems } from '../../models/main-menu-items';
import { User } from 'src/app/core/models/user';
import { MainMenuSelectService } from '../../services/main-menu-select.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { Router, NavigationEnd} from '@angular/router';

@Component({
    selector: 'app-main-menu',
    templateUrl: './create-menu.component.html',
    styleUrls: ['./create-menu.component.css']
})
export class MainMenuComponent implements OnInit, OnDestroy {
  confMessage;
  errorMsg;
  private menus = [];
  selectedIndex;
  currentUser: User;
  LeftMenus = [];
  menuSubscription;
  guestUser = false; // used to identify user has agreed to terms & conditions
  showMenu = false;
  expandedMenuId: string | null = null;
  expandedChildMenuId: string | null = null;
  expandedGrandChildMenuId: string | null = null;
  
  constructor(
    private menuItems: MainMenuItems,
    private menuService: MainMenuSelectService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
      
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x; // Get current user
      this.LeftMenus = [];
      this.setSideMenu(); // Show enabled menu
      this.closeSidebar();
    });

    router.events.subscribe((val) => {
      // see also 
      if(val instanceof NavigationEnd) {
        const path = window.location.pathname;
        if(!path.includes('/user-sign-up') && !path.includes('/error-message')){
          this.showMenu = true;
        }
      }
    });
    
  }

  ngOnInit() {
    setTimeout(() => {
      const path = window.location.pathname;
      if(!path.includes('/user-sign-up') && !path.includes('/error-message')){
        this.showMenu = true;
      }
    }, 500);
    
    if (sessionStorage.getItem('lastNavigatedIndex')) {
      this.selectedIndex = sessionStorage.getItem('lastNavigatedIndex'); // set previously selected menu item color on reinit
    }
  }

  closeSidebar(){
    setTimeout(() => {
      if(window.innerWidth<=1024){
        $('#sidebar').toggleClass('active');
        $('#header').toggleClass('active');
        $('#xeno-main-wrapper').toggleClass('active');
        $('#arrow').toggleClass('active');
      }
    }, 500);
  }
  
  onclik(){
    if (sessionStorage.getItem('length') === '0') {
      this.selectedIndex = -1; 
    }
  }

  /**
   * Loop through menu items and create menu list with permisions available
   */
  setSideMenu() {
    this.menus = this.menuItems.mainMenuenuItems;
    this.menus.forEach(menu => {
      if (this.router.url.split('/', 2)[1] === menu.mainLink) {
        this.selectedIndex = menu.menuIndex;
      }
    });

    this.LeftMenus = this.menus.map(menu => ({ ...menu })); // Create a shallow copy

    this.LeftMenus.forEach(menu => {
      this.checkPermissions(menu);
    });

    this.menuService.setDisplayMenus(this.LeftMenus); // Set created menu list to menu service
  }

  checkPermissions(menu) {
    if (this.currentUser && menu.permissions) {
      const hasPermission = this.currentUser.permissions.some(permission => {
        return menu.permissions.includes(permission);
      });

      menu.disable = !hasPermission;

      if (menu.subMenu && menu.subMenu.length > 0) {
        menu.subMenu.forEach(subMenu => {
          this.checkPermissions(subMenu);
        });
      }
    } else {
      menu.disable = true; // Disable menu if no permissions or no currentUser
    }
  }

  /**
   * For users with pending T & C will get all menu items in disable mode
   */
  setGuestMenu() {
    this.menus = this.menuItems.mainMenuenuItems;
    this.menus.forEach(menu => {
      if (this.router.url.split('/', 2)[1] === menu.mainLink) {
        this.selectedIndex = menu.menuIndex;
      }
    });

    if (this.currentUser) { // If logged user there set all menu items
            this.LeftMenus = this.menus;
      }



    this.menuService.setDisplayMenus(this.LeftMenus); // set creted list to menu service
  }

  selectItem(clickedIndex, menuLink, $event) {
    this.menuService.setPreviousIndex(this.selectedIndex);
    this.menuService.setCurrentIndex(clickedIndex);
    // Set clicked menu item for reload purpose
    sessionStorage.setItem('lastNavigatedIndex', clickedIndex );
    this.menuSubscription = this.menuService.getCurrentIndex().subscribe((value) => {
      this.selectedIndex = value;
    });
    // Handle renavigate on add basic details
    if (sessionStorage.getItem('isBasicChanged') === 'true' && this.router.url === '/employee/add/basic-details'
        && menuLink === '../employee/add/basic-details') {
      $event.stopPropagation();
      $event.preventDefault();
      this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(() => this.router.navigate(['/employee/add/basic-details'])); // handle same route navigation in basic details
    }
      if(window.innerWidth<=1024){
        $('#sidebar').toggleClass('active');
        $('#header').toggleClass('active');
        $('#xeno-main-wrapper').toggleClass('active');
        $('#arrow').toggleClass('active');
      }      
  }

  /**
   * Unsubscribe on detroy
   */
  ngOnDestroy() {
    if (this.menuSubscription) {
      this.menuSubscription.unsubscribe(); // Unsubscribe menu subcription
    }
  }

  toggleParentMenu(menuId: string): void {
    if (this.expandedMenuId === menuId) {
      this.expandedMenuId = null;

    } else {
      this.expandedMenuId = menuId;
      this.collapseOtherParentMenus(menuId);
    }
  }

  collapseOtherParentMenus(menuId: string): void {
    this.LeftMenus.forEach(menu => {
      if (menu.id !== menuId) {
        const collapseElement = document.getElementById(menu.id);
        collapseElement.classList.toggle('show', false);
        collapseElement.classList.toggle('collapse', true);
      }
    })
  }

  toggleChildMenu(childMenuId: string, childMenus: any[]): void {
    if (this.expandedChildMenuId === childMenuId) {
      this.expandedChildMenuId = null;

    } else {
      this.expandedChildMenuId = childMenuId;
      this.collapseOtherChildMenus(childMenuId, childMenus);
    }
  }

  toggleGrandChildMenu(grandChildId: string, grandChildMenu: any[]): void {
    if (this.expandedGrandChildMenuId === grandChildId) {
      this.expandedChildMenuId = null;

    } else {
      this.expandedGrandChildMenuId = grandChildId;
      this.collapseOtherChildMenus(grandChildId, grandChildMenu);
    }
  }

  collapseOtherChildMenus(menuId: string, menus: any[]): void {
    menus.forEach(menu => {
      if (menu.id !== menuId) {
        const collapseElement = document.getElementById(menu.id);
        collapseElement.classList.toggle('show', false);
        collapseElement.classList.toggle('collapse', true);
      }
    })
  }
}