/**
 * 20210409 PU 291-4911 fixed xeno-434 bug: bypass unsaved warning message when firing api common error message
 * 20210503 GC 246-4905 Added logout API request
 * 20210629 SR 465-4925 Profile image upload added
 * 20210629 SR 465-4925 Profile image get added
 * 20231215 SS XENOV2-425 error notification without routing to a page
 * 20240104 SS XENOV2-51 Set username on the navbar
 * 20240418 SS Added a nice to have a feature to load an avatar if no profile picture is updated
 */

import { Component, OnInit } from '@angular/core';
import { MenuItems } from '../../models/menu-item';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../authentication/authentication.service';
import * as $ from 'jquery';
import { MainMenuSelectService } from '../../services/main-menu-select.service';
import { NotificationUtils } from 'src/app/core/util/notification-util';
import { appConfig } from 'src/environments/environment';
import {  MatDialog } from '@angular/material';
import { ProfilePictureService } from 'src/app/modules/employee/services/profile-picture.service';
import {ProfilePictureModalsComponent} from 'src/app/core/layout/full-layout/modals/profile-picture-modals.component';
import { CoreApiService } from 'src/app/core/services/api.service';
import { ErrorPageService } from 'src/app/core/services/error-page.service';

@Component({
  selector: 'full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.css']
})
export class FullLayoutComponent implements OnInit {

  footerYear: number = new Date().getFullYear();

  private menus = [];
  selectedIndex: number;
  currentUser: string;
  displayMenus = [];
  private permissions: string[];
  portalName;
  noImageError: boolean;
  imageToShow: any;
  userFullName;


  constructor(
    private menuItems: MenuItems,
    private router: Router,
    private authenticationService: AuthenticationService,
    private mainMenuSelectService: MainMenuSelectService,
    private notificationUtils: NotificationUtils,
    private profilePictureService: ProfilePictureService,
    public dialog: MatDialog,
    private apiService: CoreApiService,
    private errorService: ErrorPageService,
    private mainMenuService: MainMenuSelectService,) {
  }

  ngOnInit() {
    this.mainMenuSelectService.getDisplayMenusIndex().subscribe((value) => {
      this.selectedIndex = value;
    });
    this.setMainmenus();

    const userName =  localStorage.getItem('currentUser');
    this.getProfilePicture(userName);
    this.getFullName(userName);

    $(document).on('click', '#open_navSidebar', function () {
      $('#offcanvas_menu').css('width', '250px');
      $("#sidebar_overlay").show();
      $('.inner-wrapper').css('overflow', 'hidden');
    });

    $(document).on('click', '#close_navSidebar', function () {
      $('#offcanvas_menu').css('width', '0px');
      $("#sidebar_overlay").hide();
      $('.inner-wrapper').css('overflow', 'scroll');
    });

    $(document).on('click', "#sidebar_overlay", function () {
      $('#offcanvas_menu').css('width', '0px');
      $("#sidebar_overlay").hide();
    });

    $(window).on('load', function () {
      $('#loader').delay(100).fadeOut('slow');
      $('#loader-wrapper').delay(500).fadeOut('slow');
      $('body').delay(500).css({ 'overflow': 'visible' });
    });

    $('#sidebarCollapse').on('click', function () {
      $('#sidebar').toggleClass('active');
      $('#header').toggleClass('active');
      $('#xeno-main-wrapper').toggleClass('active');
    });
    $('#dropDownBtn').on('click',function (event) {
      event.stopPropagation();
      $('#userDropdown').toggle();
    });

    $(document).on('click', function () {
      $('#userDropdown').hide();
    });


  }

  /**
   * get profile picture
   * @param userName
   */
  getProfilePicture(userName:string){
    this.apiService.getProfilePic(userName).subscribe(
      (res) => {
        if (res && res.httpStatusCode === 200) {
          this.noImageError = false; // set errors to default
          this.imageToShow = res.data.image;
        }
      },
      (error) => {
        if (error.errorMessage.indexOf('file already exists') >= 0) {
          this.notificationUtils.showErrorMessage(undefined, 'File already exists');
        } else if (error.errorCode === 409 || !(error.errorCode === 400 || error.errorCode === 404)) {
          this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
        }
      }
    );
  }

  /**
   * get user full name
   * @param userName
   */
  getFullName(userName:string){
    this.apiService.getFullName(userName).subscribe(
      (res) => {
        this.userFullName = res.data;
      },
      (error) => {
        if (error.errorCode === 409 || !(error.errorCode === 400 || error.errorCode === 404)) {
          this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
        }
      }
    );
  }

  setMainmenus() {
   if(localStorage.getItem('permissions')) {this.permissions = localStorage.getItem('permissions').split(',');}
    this.currentUser = localStorage.getItem('currentUser');
    this.menus = this.menuItems.mainMenuItems;
    this.menus.forEach(menu => {
      if (this.router.url.split("/", 2)[1] == menu.mainLink) {
        this.selectedIndex = menu.menuIndex;
      }
    });
    for (const value of this.menus) {
      if (this.currentUser) {
        for (const permission of this.permissions) {
          if (permission == value.permissions) {
            this.displayMenus.push(value);
          }
        }
      }
    }
  }

  menuClick(index: number) {
    this.mainMenuSelectService.setDisplayMenusIndex(index);
    this.mainMenuSelectService.getDisplayMenusIndex().subscribe((value) => {
      this.selectedIndex = value;
    });
  }

  /**Handle logout */
  logout() {

    let url = window.location.host;
    const host = appConfig.appDomain;
    const portalName = url.split('.'+host+'.'); // get string before .xeno.

    if(portalName[0] !=url){
      this.portalName = portalName[0];
    }

    if(this.portalName){
      this.authenticationService.portalLogout(this.portalName).subscribe((value) => {
        this.router.navigate(['/login']);
      });
    } else{
      this.authenticationService.logout();
    }
  }

  goLandingPage(){
    this.mainMenuService.setCurrentIndex(2);
    this.router.navigate(['/']);
  }

  /**
    * Open modal window to upload profile picture
    */
   openDialog() {
    const dialogRef = this.dialog.open(ProfilePictureModalsComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(result => {
      if (this.profilePictureService.getFileToUpload() != null) {
        this.goLandingPage();
      }
      this.ngOnInit();
    });
  }

}
