/**
 * 20231116 SS 447-5171 Dto to change the country data coming from backend
 */

export class AllCountry {
    public constructor(init?: Partial<AllCountry>) {
        Object.assign(this, init);
    }

    private countryName: string;
    private countrySymbol: string;
    private phoneCode: string;
    private countryCode: number;

    get getCountryName(): string {
        return this.countryName;
    }

    setCountryName(countryName: string): AllCountry {
        this.countryName = countryName;
        return this;
    }

    get getCountrySymbol(): string {
        return this.countrySymbol;
    }

    setCountrySymbol(countrySymbol: string): AllCountry {
        this.countrySymbol = countrySymbol;
        return this;
    }

    get getPhoneCode(): string {
        return this.phoneCode;
    }

    setPhoneCode(phoneCode: string): AllCountry {
        this.phoneCode = phoneCode;
        return this;
    }

    get getCountryCode(): number {
        return this.countryCode;
    }

    setCountryCode(countryCode: number): AllCountry {
        this.countryCode = countryCode;
        return this;
    }

}