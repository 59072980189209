/**
 * 20201022 RP 126-4845 add this class to common function related to system
 * 20231115 SS 447-5171 add isDefaultInt function to check number is default number
 * 20231107 SS XENOV2-434 5169 added isDateGreater function
 */
import { Injectable } from "@angular/core";

@Injectable()
export class CommonUtils {
    //add this function to check string is empty or sapces
    isEmptyOrSpaces(str){
        return str === null || str.match(/^ *$/) !== null;
    }

    // add this function to check number is default number
    isDefaultInt(num){
        return num === 0;
    }

    isDateGreater(d1: Date, d2: Date): boolean {
        const year1 = d1.getFullYear();
        const month1 = d1.getMonth();
        const day1 = d1.getDate();
    
        const year2 = d2.getFullYear();
        const month2 = d2.getMonth();
        const day2 = d2.getDate();
    
        if (year1 > year2) {
            return true;
        } else if (year1 === year2) {
            if (month1 > month2) {
                return true;
            } else if (month1 === month2) {
                return day1 > day2;
            }
        }
    
        return false;
    }

    /**
     * This method first, trims the trailing and leading white spaces of the given string.
     * Then replaces all white spaces between two words with one white space
     * @param inputString 
     * @returns 
     */
    trimWhiteSpaces(inputString: string): string {
        return inputString.trim().replace(/\s+/g, ' ');
    }
}