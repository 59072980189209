/**
 * 20210129 CV 208-4891 Added methods to handle reset password.
 * 20210208 CV 208-4891 Removed unused declarations
 * 20210319 GC 208-4891 Added enter key submit
 * 20231215 SS XENOV2-425 error notification without routing to a page
 */

import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomValidators } from '../../../signup/components/signup/custom-validators';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { ErrorPageService } from '../../../../core/services/error-page.service';
import { NotificationUtils } from 'src/app/core/util/notification-util';
import { appConfig } from 'src/environments/environment';
@Component({
  selector: 'resetpwd',
  templateUrl: './do-resetpwd.component.html',
  styleUrls: ['./do-resetpwd.component.css']
})
export class DoResetpwdComponent implements OnInit {
  public frmResetPwd: FormGroup;
  pwResetToken: string;
  showForm; // true if no redirection needed with tiken validation
  portalName;
  logonToken;
  sameAsOld = false;
  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private errorService: ErrorPageService,
    private notificationUtils: NotificationUtils,) { }

  ngOnInit() {
    this.frmResetPwd = this.createResetPasswordForm(); // create signup form
    this.activatedRoute.queryParams.subscribe(params => {
      this.pwResetToken = params['token'];
    });

    this.checkresetPasswordLink();

    this.frmResetPwd.get('password').valueChanges.subscribe(value => {
      if (!this.frmResetPwd.controls.password.invalid) {
        this.frmResetPwd.controls.confirmPassword.enable(); // enable confirm password field
      }
      else {
        this.frmResetPwd.controls.confirmPassword.disable(); // disable confirm password field
      }
    });

     //Move focus on next field when enter is pressed
     $(document).on('keypress', 'input,select', function (e) {
      if (e.key == "Enter") {
        e.preventDefault();
        var $next = $('[tabIndex=' + (+this.tabIndex + 1) + ']');
        $next.focus();
      }
    });
  }

  /**
   * Create signup form
   */
  createResetPasswordForm(): FormGroup {
    return this.fb.group(
      {
        email: [
          null
        ],
        password: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),

            Validators.minLength(8),


          ])
        ],
        confirmPassword: [{ value: null, disabled: true }, Validators.compose([Validators.required])],


      },
      {
        // check whether password and confirm password match
        validator: [CustomValidators.passwordMatchValidator, CustomValidators.equaltoUsernameValidator]
      },


    );
  }

  //clear all fields
  clearFields(){
    this.frmResetPwd.controls.password.setValue(null);
    this.frmResetPwd.controls.confirmPassword.setValue(null);
    this.frmResetPwd.controls.confirmPassword.setErrors(null);

    this.frmResetPwd.updateValueAndValidity();
  }

  checkresetPasswordLink() {
    let url = window.location.host;
    const host = appConfig.appDomain;
    var portalName = url.split('.'+host+'.'); // get string before .xeno.
    if (portalName[0] !=url) { // If portal name exist, do portal login
      this.checkPortalResetLink();
    } else {
      this.checkLogonResetLink();
    }

  }

  //check portal reset link
  checkPortalResetLink() {
    let url = window.location.host;
    const host = appConfig.appDomain;
    var portalName = url.split('.'+host+'.'); // get string before .xeno.
    const checkResetLinkData = { // create object with available data
      portalName: portalName[0],
      pwResetToken: this.pwResetToken
    };
    console.log(checkResetLinkData);
    this.apiService.checkResetLink(checkResetLinkData).subscribe(
      (response) => {
        if (response.body.apiStatusCode === 0) {
          this.showForm = true;
          this.frmResetPwd.get('email').setValue(response.body.data.email);
        }
      },
      (error) => {
        if (error.apiStatusCode === 7) { // If user updates token
          this.notificationUtils.showErrorMessage(undefined, "Bad security token");
        } else if (error.apiStatusCode === 13) { // If the token is already used or expired
          this.notificationUtils.showErrorMessage(undefined, "The password reset link has been expired. Please request new reset link to perform this.");
        } else if (error.apiStatusCode === 18) { // If the account is locked
          this.notificationUtils.showErrorMessage(undefined, "Your account has been locked due to failed login attempts. You are not able to perform this action. Please contact us.");
        } else if (error.apiStatusCode === 19) { // If the account is locked
          this.notificationUtils.showErrorMessage(undefined, "Your account is inactive. You are not able to perform this action. Please contact us.");
        }
      })
  }

  //check logon reset link
  checkLogonResetLink() {
    const checkLogonResetLinkData = { // create object with available data
      pwResetToken: encodeURIComponent(this.pwResetToken)
    };
    this.apiService.checkLogonResetLink(checkLogonResetLinkData).subscribe(
      (response) => {
        if (response.body.apiStatusCode === 0) {
          this.showForm = true;
          this.frmResetPwd.get('email').setValue(response.body.data.email);
          this.logonToken = response.body.data.token;
        }
      },
      (error) => {
        if (error.apiStatusCode === 7) { // If user updates token
          this.notificationUtils.showErrorMessage(undefined, "Bad security token");
        } else if (error.apiStatusCode === 13) { // If the token is already used or expired
          this.notificationUtils.showErrorMessage(undefined, "The password reset link has been expired. Please request new reset link to perform this.");
        } else if (error.apiStatusCode === 18) { // If the account is locked
          this.notificationUtils.showErrorMessage(undefined, "Your account has been locked due to failed login attempts. You are not able to perform this action. Please contact us.");
        } else if (error.apiStatusCode === 19) { // If the account is locked
          this.notificationUtils.showErrorMessage(undefined, "Your account is inactive. You are not able to perform this action. Please contact us.");
        }
      })
  }

  //reset password using potal name
  portalPasswordReset() {
    let url = window.location.host;
    const host = appConfig.appDomain;
    var portal = url.split('.'+host+'.'); // get string before .xeno.
    const passwordResetData = { // create object with available data
      token: this.pwResetToken,
      password: this.frmResetPwd.get('password').value,
      portalName: portal[0],
      modBy: this.frmResetPwd.get('email').value

    };
    this.apiService.resetUserPassword(passwordResetData).subscribe( // send to API
      (response) => {
        if (response.body.apiStatusCode === 2) {
          this.router.navigate(['/login']);
          this.notificationUtils.showSuccessMessage(undefined, 'Password reset successfully');
        }
      },
      (error) => {
        if (error.apiStatusCode === 13) { // If the token is already used or expired
          this.notificationUtils.showErrorMessage(undefined, "The password reset link has been expired. Please request new reset link to perform this.");
        } else if (error.apiStatusCode === 5) { // If the token is already used or expired
          this.sameAsOld = true;
        } else if (error.apiStatusCode === 18) { // If account is locked
          this.notificationUtils.showErrorMessage(undefined, "Your account has been locked due to failed login attempts. You are not able to perform this action. Please contact us.");
        } else if (error.apiStatusCode === 19) { // If account is locked
          this.notificationUtils.showErrorMessage(undefined, "Your account is inactive. You are not able to perform this action. Please contact us");
        }
      }
    )

  }

  //reset password using logon token
  logonPasswordReset() {
    let url = window.location.host;
    const host = appConfig.appDomain;
    var portal = url.split('.'+host+'.'); // get string before .xeno.
    const logonPasswordResetData = { // create object with available data
      pwResetToken: this.pwResetToken,
      password: this.frmResetPwd.get('password').value,
      email: this.frmResetPwd.get('email').value

    };
    this.apiService.resetLogonPassword(logonPasswordResetData).subscribe( // send to API
      (response) => {
        if (response.body.apiStatusCode === 2) {
          this.router.navigateByUrl('/login?token='+this.logonToken);
          this.notificationUtils.showSuccessMessage(undefined, 'Password reset successfully');
        }
      },
      (error) => {
        if (error.apiStatusCode === 13) { // If the token is already used or expired
          this.notificationUtils.showErrorMessage(undefined, "The password reset link has been expired. Please request new reset link to perform this.");
        } else if (error.apiStatusCode === 5) { // If the token is already used or expired
          this.sameAsOld = true;
        } else if (error.apiStatusCode === 18) { // If account is locked
          this.notificationUtils.showErrorMessage(undefined, "Your account has been locked due to failed login attempts. You are not able to perform this action. Please contact us.");
        } else if (error.apiStatusCode === 19) { // If account is locked
          this.notificationUtils.showErrorMessage(undefined, "Your account is inactive. You are not able to perform this action. Please contact us");
        }
      }
    )

  }

   //submit the form when the user hits the enter button in the last form field.
   keyDownFunction(event) {
    if (event.keyCode === 13 && !this.frmResetPwd.invalid) {
      this.passwordReset();
    }
  }


  //reset the password using portal or logon
  passwordReset() {
    let url = window.location.host;
    const host = appConfig.appDomain;
    var portal = url.split('.'+host+'.'); // get string before .xeno.
    if (portal[0] !=url) { // If portal name exist, do portal login
      this.portalPasswordReset();
    } else {
      this.logonPasswordReset();
    }
  }

  changeInput(){
    this.sameAsOld = false;
  }
}


