/**
 * 20240401 SV XENOV2-638 MyLeave dashboard
 * 20240409 SV XENOV2-638 Add getMonthlyWiseLeaveDetails API
 * 20240422 SV XENOV2-638 MyLeave dashboard added permission set & added temporary buttons
 * 20240508 HP XENOV2-695 Dashboard enhancements
 * 20240829 SV XENOV2-695 Dashboard Enhancement Frontend and UI 
 */

import { Component, OnInit, } from '@angular/core';
import * as Chart from 'chart.js';
import { ChangeDetectorRef } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NotificationUtils } from '../../util/notification-util';
import { CoreApiService } from '../../services/api.service';
import { Color, Label } from 'ng2-charts';
import { ChartOptions, ChartType } from 'chart.js';
import { MatDialog } from '@angular/material';
import { SummaryChartModalComponent } from './summary-chart-modal/summary-chart-modal.component';

@Component({
  selector: 'app-common-dashboard',
  templateUrl: './common-dashboard.component.html',
  styleUrls: ['./common-dashboard.component.css'],
})

export class CommonDashboardComponent implements OnInit {

  // start coding SV
  personalAvailable: any[] = [];
  userList: any[] = [];
  todayStatistics: any;
  departmentList: any[] = [];
  LeaveTypes: any[] = [];
  LeaveSegments: any[] = [];
  selectedLeaveType: number = 0;
  selectedLeaveSegment: number = 0;
  selectedValueDepartmentWidget1: number = 0; // For Widget 1
  selectedValueDepartmentWidget2: number = 0; // For Widget 2
  filteredTodayStatistics: any[] = []; // For Widget 1
  overallLeaveStatistics: any = {}; // For Widget 2
  chartData: any[] = [];
  lineChartColors: Color[];
  lineChartLabels: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug','Sep','Oct','Nov','Dec'];
  lineChartOptions: ChartOptions = { responsive: true, };
  lineChartLegend = true;
  lineChartType: ChartType = 'line';
  departmentUtilize: any[] = [];
  CompLeaveSummary: any[] = [];
  DeptLeaveSummary: any[] = [];
  LeaveTypeWiseCount: any[] = [];
  // Company Overall Leave Summary
  barChartData: Chart.ChartDataSets[] = [];
  barChartLabels: Label[] = [];
  barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        }
      }]
    }
  };
  barChartLegend = true;
  barChartType: string = 'bar';
  barChartPlugins = [];
  selectedDateOne: Date = new Date;
  // My Department Overall Leave Summary
  barChartData2: Chart.ChartDataSets[] = [];
  barChartLabels2: Label[] = [];
  barChartOptions2: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        }
      }]
    }
  };
  barChartLegend2 = true;
  barChartType2: string = 'bar';
  barChartPlugins2 = [];
  selectedDateTwo: Date = new Date;
  selectedDateCalendar: Date = new Date;
  overallLeaveCount: number;
  LeaveTypeWiseCountUnfiltered: any[] = [];
  //old permission
  viewLeaveSummaryChart = localStorage.getItem('permissions') ? localStorage.getItem('permissions').includes("LEAVE_SUMMARY_CHART") : false;
  // new permissions
  viewMyLeaveTab = localStorage.getItem('permissions') ? localStorage.getItem('permissions').includes("MY_LEAVE") : false;
  viewCompanyStatisticsTab = localStorage.getItem('permissions') ? localStorage.getItem('permissions').includes("COMPANY_STATISTICS") : false;
  //MyLeave Tab
  viewMyLeaveStatistics = localStorage.getItem('permissions') ? localStorage.getItem('permissions').includes("MY_LEAVE_STATISTICS") : false; //My Leave Widget
  viewMyLeaveSummary = localStorage.getItem('permissions') ? localStorage.getItem('permissions').includes("MY_LEAVE_SUMMARY") : false; //My Leave Summary Widget
  viewCompanyLeaveDetails = localStorage.getItem('permissions') ? localStorage.getItem('permissions').includes("OVERALL_LEAVE_DETAILS") : false; //Calendar Widget
  //CompanyStatistics Tab
  viewAllDepartmentLeaveStatistics = localStorage.getItem('permissions') ? localStorage.getItem('permissions').includes("OVERALL_LEAVE_STATISTICS") : false; //Overall Leave Statistics & Today Statistics
  ViewCompanyLeaveSummary = localStorage.getItem('permissions') ? localStorage.getItem('permissions').includes("COMPANY_SUMMARY") : false; //Company Overall Leave Summary Widget
  viewDepartmentLeaveSummary = localStorage.getItem('permissions') ? localStorage.getItem('permissions').includes("DEPARTMENT_SUMMARY") : false; // My Department Overall Leave Summary Widget
  myLeaveActive: boolean = true; // Initially set My Leave button as active
  companyStatsActive: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private notificationUtils: NotificationUtils,
    private apiService: CoreApiService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.loadPersonalAvailable();
    this.loadTodayStatistics();
    this.loadDepartments();
    this.loadPersonalChart();
    this.getLeaveStatistics();
    this.getCompLeaveSummary(this.selectedDateOne);
    this.getDeptLeaveSummary(this.selectedDateTwo);
    this.getLeaveTypeWiseCount(this.selectedDateCalendar);
    this.getLeaveTypes();
    this.getSegments();
  }

  loadPersonalAvailable() {
    if (this.viewMyLeaveStatistics) {
      this.apiService.getEmployeeLeaveDetails().subscribe(
        (response) => {
          if (response.httpStatusCode === 200) {
            this.personalAvailable = this.formatPersonalData(response.data);
          }
        },
        (error) => {
          if (error.httpStatusCode === 409 || !(error.httpStatusCode === 400 || error.httpStatusCode === 404)) {
            this.notificationUtils.showErrorMessage(error.errorMessage, error.data);
          }
        }
      );
    }
  }
  
  formatPersonalData(personalData: any[]): any[] {
    for (let data of personalData) {
      // Ensure balance and utilized are numbers
      const balance = Number(data.balance) || 0;
      const utilized = Number(data.utilized) || 0;
  
      // Prepare data array for the chart
      data.data = [balance, utilized]; // Array of numbers
  
      // Prepare colors array for the chart
      data.colors = [{
        backgroundColor: [data.leaveTypeColor, '#FFFFFF'] // Array of colors
      }];
  
      // Set a default chart type if it's not defined
      data.type = data.type || 'doughnut';
    }
  
    return personalData;
  }

  loadDepartments() {
    this.apiService.getDepartments().subscribe(
      (response) => {
        if (response.apiStatusCode === 0) {
          this.departmentList = response.data;
          this.selectedValueDepartmentWidget1 = 0; // Default selection for Widget 1
          this.selectedValueDepartmentWidget2 = 0; // Default selection for Widget 2
        }
      },
      (error) => {
        if (error.errorCode === 409) {
          this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
        } else if (!(error.errorCode === 400 || error.errorCode === 404)) {
          this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
        }
      }
    );
  }

  loadTodayStatistics() {
    if (this.viewAllDepartmentLeaveStatistics){
      // Step 1: Get the current date
      const currentDate = new Date();
    
      // Step 2: Format the date if necessary (e.g., 'YYYY-MM-DD')
      const formattedDate = currentDate.toISOString().split('T')[0];
    
      // Step 3: Call the API with the formatted date as a parameter
      this.apiService.getDeptWiseLeaveCount(formattedDate).subscribe(
        (response) => {
          if (response.httpStatusCode === 200) {
            this.todayStatistics = response.data;
            this.filterStatistics();
          }
        },
        (error) => {
          if (error.errorCode === 409) {
            this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
          } else if (!(error.errorCode === 400 || error.errorCode === 404)) {
            this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
          }
        }
      );
    }
  }

  filterStatistics() {
    // Filtering for Widget 1
    this.filteredTodayStatistics = this.todayStatistics.deptWiseLeaveCount
      ? this.todayStatistics.deptWiseLeaveCount[this.selectedValueDepartmentWidget1] || []
      : [];

    // Filtering for Widget 2
    this.overallLeaveStatistics = this.departmentUtilize.find(
      (dep) => dep.deptCode == this.selectedValueDepartmentWidget2
    );
  }

  // Method to generate the gradient background string
  getGradientBackground(color: string): string {
    const opaqueColor = this.hexToRgba(color, 1); // 100% opacity
    const transparentColor = this.hexToRgba(color, 0.4); // 40% opacity
    return `linear-gradient(to bottom, ${opaqueColor} 0%, ${transparentColor} 100%)`;
  }

  // Helper method to convert hex color to rgba format
  hexToRgba(hex: string, opacity: number): string {
    let r = 0,
      g = 0,
      b = 0;

    // Handle short hex format (#abc)
    if (hex.length == 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } 
    // Handle full hex format (#aabbcc)
    else if (hex.length == 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  loadPersonalChart() {
    if (this.viewMyLeaveSummary){
      this.apiService.getMonthlyWiseLeaveDetails().subscribe(
        (response) => {
          if (response.httpStatusCode === 200 ) {
            const { chartDataList, chartColorList } = this.formatLineChart(response.data);
            this.chartData = chartDataList;
            this.lineChartColors = chartColorList;
          }
        },
        (error) => {
          if (error.httpStatusCode === 409 || !(error.httpStatusCode === 400 || error.httpStatusCode === 404)) {
            this.notificationUtils.showErrorMessage(error.errorMessage, error.data);
          }
        }
      );
    }
  }

  formatLineChart(existingData) {
    const chartDataList = [];
    const chartColorList = [];
    for (let data of existingData) {
      chartColorList.push({ borderColor: data.leaveTypeColor, backgroundColor: data.leaveTypeColor+'20' });
      chartDataList.push({ data: data.data , label: data.leaveTypeName, tension: 0.1 });
    }
    return {chartDataList,chartColorList};
  }

  getLeaveStatistics(){
    if (this.viewAllDepartmentLeaveStatistics){
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split('T')[0];
      this.apiService.getLeaveStatistics(formattedDate).subscribe(
        (response) => {
          if (response.httpStatusCode === 200 ) {
            response.data.forEach(departmentUtilize => {
              this.departmentUtilize.push(this.formatDepartmentData(departmentUtilize));
            });
            this.filterStatistics();
          }
        },
        (error) => {
          if (error.httpStatusCode === 409 || !(error.httpStatusCode === 400 || error.httpStatusCode === 404)) {
            this.notificationUtils.showErrorMessage(error.errorMessage, error.data);
          }
        }
      );
    }
  }

  formatDepartmentData(departmentData){
    for(let data of departmentData.leaveUtilizedList){
      let chartDataList = [];
      chartDataList.push(data.balance);
      chartDataList.push(data.utilized);
      data.data = chartDataList;
      let colors = [];
      colors.push(data.leaveTypeColor);
      colors.push("#FFFFFF")
      data.colors = [{ backgroundColor: colors }];
    }
    return departmentData;
  }

  //Company Leave Summary

  onDateChangeOne(event: any) {
    this.selectedDateOne = event.value; // Update the selected date
    this.getCompLeaveSummary(this.selectedDateOne); // Call the API with the new date
  }

  getCompLeaveSummary(selectedDateOne: Date) {
    if(this.ViewCompanyLeaveSummary){
      const formattedDate = this.formatDateToYYYYMMDD(selectedDateOne);  
    this.apiService.getCompLeaveSummary(formattedDate).subscribe(
      (response) => {
        if (response.httpStatusCode === 200) {
          this.CompLeaveSummary = response.data;
          this.barChartLabels = []; 
          this.barChartData = [];
          this.prepareCompChartData(response.data);
        }
      },
      (error) => {
        if (error.httpStatusCode === 409 || !(error.httpStatusCode === 400 || error.httpStatusCode === 404)) {
          this.notificationUtils.showErrorMessage(error.errorMessage, error.data);
        }
      }
    );
    }
  }

  prepareCompChartData(data: any) {
    // Get and sort the dates in ascending order
    const dates = Object.keys(data).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  
    // Clear previous chart labels and data
    this.barChartLabels = [];
    const leaveTypeDataMap = new Map<string, { data: number[], backgroundColor: string }>();
  
    dates.forEach(date => {
      // Set chart labels to the sorted dates
      this.barChartLabels.push(date);
  
      data[date].forEach((leave: any) => {
        if (!leaveTypeDataMap.has(leave.leaveTypeName)) {
          leaveTypeDataMap.set(leave.leaveTypeName, { data: Array(dates.length).fill(0), backgroundColor: leave.color });
        }
  
        // Find the index of the current date and update the count
        const leaveType = leaveTypeDataMap.get(leave.leaveTypeName);
        if (leaveType) {
          leaveType.data[dates.indexOf(date)] = leave.leaveCount;
        }
      });
    });
  
    this.barChartData = Array.from(leaveTypeDataMap.entries()).map(([leaveTypeName, { data, backgroundColor }]) => ({
      label: leaveTypeName,
      data,
      backgroundColor
    }));
  }  

  //Department Leave Summary

  onDateChangeTwo(event: any) {
    this.selectedDateTwo = event.value; // Update the selected date
    this.getDeptLeaveSummary(this.selectedDateTwo); // Call the API with the new date
  }

  getDeptLeaveSummary(selectedDateTwo: Date){
    if (this.viewDepartmentLeaveSummary){
      const formattedDate = this.formatDateToYYYYMMDD(selectedDateTwo);  
      this.apiService.getDeptLeaveSummary(formattedDate).subscribe(
        (response) => {
          if (response.httpStatusCode === 200) {
            this.DeptLeaveSummary = response.data;
            this.barChartLabels2 = []; 
            this.barChartData2 = [];
            this.prepareDeptChartData(response.data);
          }
        },
        (error) => {
          if (error.httpStatusCode === 409 || !(error.httpStatusCode === 400 || error.httpStatusCode === 404)) {
            this.notificationUtils.showErrorMessage(error.errorMessage, error.data);
          }
        }
      );
    }
  }

  prepareDeptChartData(data: any) {
    // Get and sort the dates in ascending order
    const dates = Object.keys(data).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  
    // Clear previous chart labels and data
    this.barChartLabels2 = [];
    const leaveTypeDataMap = new Map<string, { data: number[], backgroundColor: string }>();
  
    dates.forEach(date => {
      // Set chart labels to the sorted dates
      this.barChartLabels2.push(date);
  
      data[date].forEach((leave: any) => {
        if (!leaveTypeDataMap.has(leave.leaveTypeName)) {
          leaveTypeDataMap.set(leave.leaveTypeName, { data: Array(dates.length).fill(0), backgroundColor: leave.color });
        }
  
        // Find the index of the current date and update the count
        const leaveType = leaveTypeDataMap.get(leave.leaveTypeName);
        if (leaveType) {
          leaveType.data[dates.indexOf(date)] = leave.leaveCount;
        }
      });
    });
  
    this.barChartData2 = Array.from(leaveTypeDataMap.entries()).map(([leaveTypeName, { data, backgroundColor }]) => ({
      label: leaveTypeName,
      data,
      backgroundColor
    }));
  }  

  formatDateToYYYYMMDD(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are 0-based in JavaScript
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  // Calendar widget

  calendarDate(event: any){
    this.selectedDateCalendar = event; // Update the selected date
    this.getLeaveTypeWiseCount(this.selectedDateCalendar); // Call the API with the new date
  }

  getLeaveTypeWiseCount(selectedDateCalendar: Date){
    if (this.viewCompanyLeaveDetails){
      const formattedDate = this.formatDateToYYYYMMDD(selectedDateCalendar);
      this.apiService.getLeaveTypeWiseCount(formattedDate).subscribe(
        (response) => {
          if (response.httpStatusCode === 200) {
            this.LeaveTypeWiseCountUnfiltered = response.data.leaveDetails;
            this.overallLeaveCount = response.data.totalCount; 
            this.filterLeaveUserList();
          }
        },
        (error) => {
          if (error.httpStatusCode === 409 || !(error.httpStatusCode === 400 || error.httpStatusCode === 404)) {
            this.notificationUtils.showErrorMessage(error.errorMessage, error.data);
          }
        }
      );
    }
  }

  filterLeaveUserList(){

    const filterLogic = (user) => {
    const leaveTypeMatch = this.selectedLeaveType ?
      user.leaveTypeCode === this.selectedLeaveType : true;
        
    const leaveSegmentMatch = this.selectedLeaveSegment ?
      user.segment === this.selectedLeaveSegment : true;

      return leaveSegmentMatch && leaveTypeMatch;
    };

    this.LeaveTypeWiseCount = this.LeaveTypeWiseCountUnfiltered.filter(filterLogic);
  }

  openSummaryChartModal() {
    const dialogRef = this.dialog.open(SummaryChartModalComponent, {
      width: '600px',
      disableClose: false,
      data: {
        depCode: this.selectedValueDepartmentWidget2
      }
    });
  }

  getLeaveTypes() {
    this.apiService.getLeaveTypes().subscribe(
      (response) => {
        if (response.httpStatusCode === 200) {
          this.LeaveTypes = response.data;
        }
      },
      (error) => {
        if (error.httpStatusCode === 409 || !(error.httpStatusCode === 400 || error.httpStatusCode === 404)) {
          this.notificationUtils.showErrorMessage(error.errorMessage, error.data);
        }
      }
    );
  }

  getSegments() {
    this.apiService.getSegments().subscribe(
      (response) => {
        if (response.httpStatusCode === 200) {
          this.LeaveSegments = response.data;
        }
      },
      (error) => {
        if (error.httpStatusCode === 409 || !(error.httpStatusCode === 400 || error.httpStatusCode === 404)) {
          this.notificationUtils.showErrorMessage(error.errorMessage, error.data);
        }
      }
    );
  }

  toggleMyLeave() {
    if (this.viewMyLeaveTab) {
      this.myLeaveActive = true;
      this.companyStatsActive = false;    
    }
  }

  toggleCompanyStats() {
    if (this.viewCompanyStatisticsTab){
      this.myLeaveActive = false;
      this.companyStatsActive = true;
    }
  }

  getUserStyle(user: any): any {
    switch (user.segment) {
      case 1:
        return {
            'border-color': user.color,
            'background': `linear-gradient(270deg, transparent 50%, ${user.color} 50%)`
        };
      case 2:
        return {
            'border-color': user.color,
            'background': `linear-gradient(270deg, ${user.color} 50%, transparent 50%)`
        };
      case 3:
        return {
            'border-color': user.color,
            'background': `linear-gradient(90deg, ${user.color} 50%, ${user.color} 50%)`
        };
      default:
        return {
            'border-color': user.color
        };
    }
  }
}
