import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class MainMenuSelectService {

    private displayMenusIndex: BehaviorSubject<any>;
    private displayMenus: any;
    private previousIndex: number;
    private curruntIndex: BehaviorSubject<any>;
    constructor() {
        this.displayMenusIndex = new BehaviorSubject<any>(false);
        this.curruntIndex = new BehaviorSubject<any>(false);
    }

    setDisplayMenusIndex(displayMenusIndex: any): void {
        this.displayMenusIndex.next(displayMenusIndex);
    }

    getDisplayMenusIndex(): Observable<any> {
        return this.displayMenusIndex.asObservable();
    }

    setDisplayMenus(displayMenus: any) {
        this.displayMenus = displayMenus;
    }

    getDisplayMenus() {
        return this.displayMenus;
    }

    setPreviousIndex(index) {
        this.previousIndex = index;
    }

    setCurrentIndex(index: any): void {
        this.curruntIndex.next(index);
    }

    getPreviousIndex() {
        return this.previousIndex;
    }

    getCurrentIndex() {
        return this.curruntIndex.asObservable();
    }
}