/**
 * 20201119 GC 190-4871 created this class for the Rest services
 * 20210122 GC 222-4887 Added acceptTerms()
 * 20210219 RP 228-4892 update the code to remove the cmpCode from the request
 * 20210623 SR 465-4925 Profile picture related API calls added
 * 20240129 SS 521 Added Portal QR Code
 * 20240401 SV XENOV2-638 MyLeave dashboard
 * 20240409 SV XENOV2-638 Add getMonthlyWiseLeaveDetails API
 * 20240422 SV XENOV2-638 MyLeave dashboard New APIs getEmpLeaveDaysInDep, getCompanyAllLeaveDetails Connected.
 * 20240418 SS Added a nice to have a feature to load an avatar if no profile picture is updated
 * 20240829 SV XENOV2-695 Dashboard Enhancement Frontend and UI 
 */

import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { ENDPOINTS } from '../util/api-endpoints';
import { appConfig } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class CoreApiService {

    constructor(private httpService: HttpService) {
    }

    // Get landing page config
    getLandingPageConfig() {
        const url = appConfig.appRoot.concat(ENDPOINTS.getOrgConfig);
        return this.httpService.httpGet(url);
    }
    /**
     * Method to send terms & condition accepted request
     * @param body 
     */
    acceptTerms(body){
        const url = appConfig.appRoot.concat(ENDPOINTS.acceptTerms);
        return this.httpService.httpPost(url, body);
    }

    getCompanyQR() {
        const url = appConfig.appRoot.concat(ENDPOINTS.getCompanyQR);
        return this.httpService.httpGet(url, { observe: 'response', responseType: 'blob' });
    }
    
    // Get landing page config
    getProfilePic(userName) {
        const url = appConfig.appRoot.concat(ENDPOINTS.getProfilePic).replace('{username}', userName);
        return this.httpService.httpGet(url);
    }

    // Get user full name
    getFullName(userName) {
        const url = appConfig.appRoot.concat(ENDPOINTS.getFullName).replace('{username}', userName);
        return this.httpService.httpGet(url);
    }

    // Upload profile picture location
    uploadProfilePic(file , userName:string): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        const url = appConfig.appRoot.concat(ENDPOINTS.uploadProfilePic).replace('{username}', userName);
        return this.httpService.httpPut(url, formData);
    }

    getDepartmentLeaveDetails() {
        const url = appConfig.appRoot.concat(ENDPOINTS.getDepartmentLeaveDetails);
        return this.httpService.httpGet(url);
    }
    
    getAllDepartmentLeaveDetails() {
        const url = appConfig.appRoot.concat(ENDPOINTS.getAllDepartmentLeaveDetails);
        return this.httpService.httpGet(url);
    }

    getCompanyLeaveDetails() {
        const url = appConfig.appRoot.concat(ENDPOINTS.getCompanyLeaveDetails);
        return this.httpService.httpGet(url);
    }

    getEmployeeLeaveDetails() {
        const url = appConfig.appRoot.concat(ENDPOINTS.getEmployeeLeaveDetails);
        return this.httpService.httpGet(url);
    }

    getMonthlyWiseLeaveDetails() {
        const url = appConfig.appRoot.concat(ENDPOINTS.getMonthlyWiseLeaveDetails);
        return this.httpService.httpGet(url);
    }

    getEmpLeaveDaysInDep(depCode: any) {
        const url = appConfig.appRoot.concat(ENDPOINTS.getEmpLeaveDaysInDep).replace('{depCode}', depCode);
        return this.httpService.httpGet(url);
    }

    getCompanyAllLeaveDetails(date: any) {
        const url = appConfig.appRoot.concat(ENDPOINTS.getCompanyAllLeaveDetails).replace('{date}', date);
        return this.httpService.httpGet(url);
    }

    getDepartments() {
        const url = appConfig.appRoot.concat(ENDPOINTS.getDepartments);
        return this.httpService.httpGet(url);
    }

    getDeptWiseLeaveCount(date: any) {
        const url = appConfig.appRoot.concat(ENDPOINTS.getDeptWiseLeaveCount).replace('{date}', date);
        return this.httpService.httpGet(url);
    }

    getLeaveStatistics(date: any) {
        const url = appConfig.appRoot.concat(ENDPOINTS.getLeaveStatistics).replace('{date}', date);
        return this.httpService.httpGet(url);
    }

    getCompLeaveSummary(date: any) {
        const url = appConfig.appRoot.concat(ENDPOINTS.getCompLeaveSummary).replace('{date}', date);
        return this.httpService.httpGet(url);
    }

    getDeptLeaveSummary(date: any) {
        const url = appConfig.appRoot.concat(ENDPOINTS.getDeptLeaveSummary).replace('{date}', date);
        return this.httpService.httpGet(url);
    }

    getLeaveTypeWiseCount(date: any) {
        const url = appConfig.appRoot.concat(ENDPOINTS.getLeaveTypeWiseCount).replace('{date}', date);
        return this.httpService.httpGet(url);
    }

    getLeaveTypes() {
        const url = appConfig.appRoot.concat(ENDPOINTS.getLeaveTypes);
        return this.httpService.httpGet(url);
    }

    getSegments() {
        const url = appConfig.appRoot.concat(ENDPOINTS.getSegments);
        return this.httpService.httpGet(url);
    }
}
