/**
 * 20210115 PU 245-4897 common email validator
 * 20210209 PU 245-4897 regex modified to disallow leading and trailing dot, consecutive dots in both local and domain parts of email.
 */
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class EmailValidatorService {

  private emailRegex = /^[\S]+@([\S]+\.)+[\S]+$/;
  private emailRegRFC2822 = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  constructor() {
  }

  validate(email) {
    if (email != null && email !== '' && this.emailRegRFC2822.test(email) && this.emailRegex.test(email)) {
      return true;
    } else {
      return false;
    }
  }
}
