/**
 * 20200929 RP 126-4845 init this class to add organization based apis
 * 20210202 RP 253-4908 remove the getCountries method
 * 20231115 SS 447-5171 align to t:COUNTRY changes
 */
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { appConfig } from 'src/environments/environment';
import { ENDPOINTS } from '../util/api-endpoints';
import { Observable } from 'rxjs';

@Injectable()
export class OrgApiService {

    constructor(private httpService: HttpService) {}

    //get the user location details
    getIpDetails(){
        return this.httpService.httpGet(appConfig.getIpDetails); 
    }

    //save the company sign up details in DB
    saveSignUp(signUp: any): Observable<any> {
        const url = appConfig.appRoot.concat(ENDPOINTS.saveSignUp);
        return this.httpService.httpPost(url, signUp);
    }

    // get countries list from t:COUNTRY
    getCountries() {
        const url = appConfig.appRoot.concat(ENDPOINTS.getCountries);
        return this.httpService.httpGet(url);
    }
    
}


