import { Component, OnInit,  OnDestroy} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
export let browserRefresh = false;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'xeno_pl';
  browserRfreshSubscription: Subscription;

  ngOnInit() {

}

constructor(private router: Router) {
  this.browserRfreshSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
  });
}

ngOnDestroy() {
  this.browserRfreshSubscription.unsubscribe();
}

}
