/**
 * 20210518 GC 465-4925 added profile picture upload popup component preview, image chopper and file upload functions.
 * 20210719 SR 465-4925 Added Profile picture related functions
 * 20231215 SS XENOV2-425 error notification without routing to a page
 * 20240104 SS XENOV2-51 Removed unnecessary ngOnInit
 */
import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotificationUtils } from 'src/app/core/util/notification-util';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ProfilePictureService } from 'src/app/modules/employee/services/profile-picture.service';
import { CoreApiService } from 'src/app/core/services/api.service';
import { ErrorPageService } from 'src/app/core/services/error-page.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-picture-modals',
  templateUrl: './profile-picture-modals.component.html',
  styleUrls: ['./profile-picture-modals.component.css']
})

export class ProfilePictureModalsComponent {

  @Output() public found = new EventEmitter<any>();
  uploadSuccess = false;
  public imagePath;
  croppedImage: any = '';
  typeError = false;
  sizeError = false;
  imageChangedEvent: any = '';
  imgURL: any;
  uploaderText = 'Import File';
  noImageError = false;
  fileToUpload: File;
  profileImgPath: string;
  image: any;
  
  constructor(
    private dialogRef: MatDialogRef<ProfilePictureModalsComponent>,
    private notificationUtils: NotificationUtils,
    private profilePictureService: ProfilePictureService,
    private apiService: CoreApiService,
    private errorService: ErrorPageService,
    private router: Router,
  ) { }

  /**
   * Trigger warning when try to close the window
   */
  closeDialog() {
    if (this.uploadSuccess && this.imagePath) {
      this.notificationUtils.promptConfirmation('Warning', 'The selected profile picture file has not updated. Do you really want to proceed?',
        'fa-info-circle', 'warning')
        .then((result) => {
          if (result) {
            this.croppedImage = null;
            this.fileToUpload = null;
            this.dialogRef.close(); // close dialog
          }
        });
    } else {
      this.croppedImage = null;
      this.fileToUpload = null;
      this.dialogRef.close();
    }
  }

  /**
   * Trigger file input click for custom button
   */
  getFile() {
    document.getElementById('file-input').click();
  }

  /**
   * Upload the cropped image
   *
   */
  processFile(event) {
    if (this.imagePath) {
      this.profilePictureService.setFileToUpload(this.fileToUpload);
      this.profilePictureService.setCroppedImage(this.croppedImage);
      const userName =  localStorage.getItem('currentUser');
      // create the upload file with cropped area
      const fileToUpload: File = new File([this.dataURItoBlob(this.croppedImage)], this.imagePath[0].name, {type: this.imagePath[0].type});
      this.uploadProfilePicture(fileToUpload, userName);

      this.dialogRef.close();

    } else if (this.typeError) {
      this.typeError = true;
    } else if (this.sizeError) {
      this.sizeError = true;
    } else {
      this.noImageError = true;
    }
  }

  /**
   * upload profile picture
   * @param body
   * @param userName 
   */
  uploadProfilePicture(fileToUpload:any, userName:string){
    this.apiService.uploadProfilePic(fileToUpload, userName).subscribe(
      (res) => {
        this.noImageError = false; // set errors to default
        this.uploadSuccess = true;
        this.notificationUtils.showSuccessMessage(undefined, 'Profile picture updated successfully'); // Show success notification
      },
      (error) => {
        if (error.errorMessage.indexOf('file already exists') >= 0) {
          this.notificationUtils.showErrorMessage(undefined, 'File already exists');
        } else if (error.errorCode === 409) {
          this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
        } else if (!(error.errorCode === 400 || error.errorCode === 404)) {
          this.dialogRef.close();
          this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
        }

      });
  }

  /**
   * Convert croped image to blob
   *
   */
  dataURItoBlob(dataURI): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]; // Get the image type
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString }); // return blob
  }

  /**
   * When select a file, do the validations
   *
   */
  fileChangeEvent(event: any): void {
    let files = event.srcElement.files;
    this.typeError = false; // set errors to default
    this.sizeError = false;
    this.noImageError = false;
    if (files.length === 0) {
      return;
    } else {
      this.noImageError = false;
      this.imagePath = null;
      this.croppedImage = null;
    }
    const logoFileType: Array<string> = ['png', 'jpg', 'jpeg', 'gif','PNG', 'JPG', 'JPEG', 'GIF'];
    const mimeType = files[0].name.split('.').pop();
    if (logoFileType.indexOf(mimeType) === -1) { // validate the type
      this.typeError = true;
    } else if (files[0].size > maxSizeOfPic) {// validate file size
      this.sizeError = true;
      return;
    } else {
      this.imageChangedEvent = event;
      const reader = new FileReader();
      this.imagePath = files;
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.imgURL = reader.result;
        this.uploaderText = 'Remove & New'; // change button text
      };
      this.typeError = false; // set errors to default
      this.sizeError = false;
    }
  }

  /**
   * Image cropped event
   */
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64; // get base64
    this.fileToUpload = new File([this.dataURItoBlob(this.croppedImage)], this.imagePath[0].name, { type: this.imagePath[0].type });
    this.uploadSuccess = true;
  }

}

const maxSizeOfPic = 2097152;
