/**
 * 20240503 DG XENOV2-677-5229 Created confirmation-with-reason-dialog.component.sts
 * 20240529 DG XENOV2-728 Added validation to reason field
 */

import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-confirmation-with-reason-dialog',
    templateUrl: './confirmation-with-reason-dialog.component.html',
    styleUrls: ['./confirmation-with-reason-dialog.component.css']
})
export class ConfirmationWithReasonDialog implements OnInit, AfterViewInit {
    @Input() title: string;
    @Input() message: string;
    @Input() btnOkText: string;
    @Input() btnCancelText: string = '';
    @Input() showReasonInput: boolean = false;
    /**
     * Inroduced this variable to show only one button in this confirmation dialog.
     * If this variable is false, one button is displayed. The default value is set to true because,
     * Previous implementations of this dialog has not provided this input. 
     */
    @Input() showCancelButton: boolean = true;
    @ViewChild('cancelBtn', { static: false }) inputEl: ElementRef;
    headerColor;
    icon;
    reason: string = '';
    maxReasonLength: number = 255;

    constructor(private activeModal: NgbActiveModal) { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        setTimeout(() => this.inputEl.nativeElement.focus());
    }

    public decline() {
        this.activeModal.close(false);
    }

    public accept() {
        this.activeModal.close({ reason: this.reason, ok: true });
    }

    public dismiss() {
        this.activeModal.close(false);
    }
}