/**
 * 20210115 GC 222-4887 Create for settings page
 * 20240129 SS 521 Added Portal QR Code
 */

import { Component, OnInit } from '@angular/core';
import { CoreApiService } from '../../services/api.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css'] 
})
export class SettingsComponent implements OnInit {
    logonLink;
    imagePreview: string | ArrayBuffer;
    qrFile: File = null;

    constructor(
        private apiService: CoreApiService,
    ) { }

    ngOnInit() {
        let url = window.location.host; // Get host url from browser
        this.logonLink = url +'/login'; 
        this.getCompanyQR();
    }

    getCompanyQR() {
        this.apiService.getCompanyQR().subscribe(
            (response) => {
                if (response.status === 200) {
                    console.log(response.headers.get('Content-Type'));
                    this.qrFile = response.body;
                    this.readImageFile();
                }
            },
            (error) => {

            }
        );
    }

    download() {
        if (this.qrFile) {
            const fileUrl = URL.createObjectURL(this.qrFile);

            const downloadLink = document.createElement('a');
            downloadLink.href = fileUrl;
            downloadLink.download = this.qrFile.name || 'download'; // Set the desired file name

            // Trigger the download
            document.body.appendChild(downloadLink);
            downloadLink.click();

            // Clean up
            document.body.removeChild(downloadLink);

            // Revoke the object URL to free up resources
            URL.revokeObjectURL(fileUrl);
        }
    }

    readImageFile() {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            this.imagePreview = event.target.result as string;
        };
        reader.readAsDataURL(this.qrFile);
    }

}
