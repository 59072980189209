/**
 * 20210126 CV 208-4891 Create this class to define the rest end points 
 */
export const ENDPOINTS = {
    sendPasswordResetLink: '/auth/forgot-pwd', // end point for send password reset link
    checkResetLink: '/auth/reset-link-validation',
    resetUserPassword: '/auth/reset-pwd',
    sendLogonPasswordResetLink: '/auth/logon/forgot-pwd',
    checkLogonResetLink: '/auth/logon/reset-link-validation',
    resetLogonPassword: '/auth/logon/reset-pwd'
};
