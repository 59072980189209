/**
 * 20201011 RP 126-4845 add this class to create sign up request
 * 20201021 RP 126-4845 change the cntryCode variable to cntrySymbol
 * 20231115 SS 447-5171 align to t:COUNTRY changes
 */
export class SignUp {
    public constructor(init?: Partial<SignUp>) {
        Object.assign(this, init);
    }

    private compName: string;
    private compWeb: string;
    private fullName: string;
    private email: string;
    private countryCode: number;
    private phoneNumber: string;

    get getCompName(): string {
        return this.compName;
    }

    get getCompWeb(): string {
        return this.compWeb;
    }

    get getFullName(): string {
        return this.fullName;
    }

    get getEmail(): string {
        return this.email;
    }

    get getCountryCode(): number {
        return this.countryCode;
    }

    get getPhoneNumber(): string {
        return this.phoneNumber;
    }

    setCompName(compName: string): SignUp {
        this.compName = compName;
        return this;
    }

    setCompWeb(compWeb: string): SignUp {
        this.compWeb = compWeb;
        return this;
    }

    setFullName(fullName: string): SignUp {
        this.fullName = fullName;
        return this;
    }

    setEmail(email: string): SignUp {
        this.email = email;
        return this;
    }

    setCountryCode(countryCode: number): SignUp {
        this.countryCode = countryCode;
        return this;
    }

    setPhoneNumber(phoneNumber: string): SignUp {
        this.phoneNumber = phoneNumber;
        return this;
    }
}
