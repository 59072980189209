/**
 * 20240503 DG XENOV2-677-5229 created promptConfirmationWithReason() method
 */

import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationWithReasonDialog } from './confirmation-with-reason-dialog/confirmation-with-reason-dialog.component';

@Injectable()
export class NotificationUtils {

    constructor(private toastr: ToastrService,
                private modalService: NgbModal) { }

    public basicMessage(title?: string, message?: string) {
        this.toastr.show(message, title);
    }

    public showSuccessMessage(title?: string, message?: string) {
        this.toastr.success(message, title || 'Success');
    }

    public showMessageOnly(title?: string, message?: string) {
        this.toastr.success(message, title || '');
    }

    public showErrorMessage(title?: string, message?: string) {
        this.toastr.error(message, title || 'Error');
    }

    public showWarningMessage(title?: string, message?: string) {
        this.toastr.warning(message, title || 'Warning');
    }

    public showInfoMessage(title?: string, message?: string) {
        this.toastr.info(message, title || 'Info');
    }

    public promptConfirmation(title?: string, message?: string, icon?: string, headerColor?: string): Promise<boolean> {
        const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: 'sm', backdrop: 'static', keyboard: false });
        modalRef.componentInstance.title = title || 'Are you sure?';
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.icon = icon;
        modalRef.componentInstance.headerColor = headerColor;
        modalRef.componentInstance.btnOkText = 'Yes';
        modalRef.componentInstance.btnCancelText = 'No';
        return modalRef.result;
      }

    public promptConfirmationWithReason(title: string, btnOkText: string, btnCancelText?: string, message?: string, icon?: string,
        headerColor?: string, showReasonInput?: boolean, showCancelButton?: boolean): Promise<any> {

        const modalRef = this.modalService.open(ConfirmationWithReasonDialog, { size: 'sm', backdrop: 'static' });
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.icon = icon;
        modalRef.componentInstance.headerColor = headerColor;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;
        modalRef.componentInstance.showReasonInput = showReasonInput;
        modalRef.componentInstance.showCancelButton = showCancelButton;
        return modalRef.result;
    }
}
