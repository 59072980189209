import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpService {

  constructor(private httpClient: HttpClient) { }

  httpGet(url: string, option?: any): Observable<any> {
    return this.httpClient
      .get<any>(url, option);
  }

  httpPost(url: string, body?: any, option?: any): Observable<any> {
    return this.httpClient
      .post<any>(url, body, option);
  }

  httpPut(url: string, body?: any, option?: any): Observable<any> {
    return this.httpClient
      .put<any>(url, body, option);
  }

  httpDelete(url: string, option?: any): Observable<any> {
    return this.httpClient
      .delete<any>(url, option);
  }
}