/**
 * 20201023 173-4850 GC added comments
 * 20210218 291-4911 PU moved to core module
 * 20210322 291-4911 PU fix xeno-391 bug
 * 20210406 189-4864 GC added last attempted url check
 */
import { Component, OnInit } from '@angular/core';
import { ErrorPageService } from '../../services/error-page.service'; // import error page service
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { browserRefresh } from '../../../app.component';
import { appConfig } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
@Component({
    selector: 'error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.css']
  })
  export class ErrorComponent implements OnInit {
  errorMessege = '';
  apiCode = '';
  commonError = '';
  footerYear;
  browserRefresh;
  subscription: Subscription;

  constructor(private errorPageService: ErrorPageService,  private router: Router,
    private authenticationService: AuthenticationService,) {

  }

  ngOnInit() {
    if(document.getElementById("sidebar")){document.getElementById("sidebar").style.display = "none";}
    let url = this.errorPageService.getLastUrl();
    this.browserRefresh = browserRefresh;

    this.errorMessege = this.errorPageService.getError(); // on init, get existing errors from service
    if(sessionStorage.getItem('error-message') !=='' && this.errorMessege ==''){
      this.errorMessege = sessionStorage.getItem('error-message');
    }
    if(sessionStorage.getItem('last-navigated-url') !=='' && !url){
      url = sessionStorage.getItem('last-navigated-url');
    }

    if(this.browserRefresh && url){
      this.router.navigate([url]);
    }
    if(this.browserRefresh){
      this.checkBackend();
    }

    sessionStorage.setItem('error-message', this.errorMessege);
    sessionStorage.setItem('last-navigated-url', url);
    if (this.errorMessege && this.errorMessege.includes('common-error')) {
      this.errorMessege = 'Oops!';
      const errorParts = this.errorMessege.split('|');
      this.apiCode = errorParts[1];
      if (this.apiCode && this.apiCode!='undefined') {
        this.apiCode = 'code(' + this.apiCode + ')';
      } else {
        this.apiCode = 'code(1)'; // this is to indicate internal server error api status code
      }
      this.errorMessege = 'Oops!';
      this.commonError = '<span><p>Please contact our support team. <br><p> &lt;contact phone number&gt;</p> <p>&lt;contact email address&gt;</p></p></span>';
    }
    if(sessionStorage.getItem('error-message') == "null" && this.errorMessege =="null"){
      this.router.navigateByUrl('/login');
    }

    localStorage.removeItem('user');
                localStorage.removeItem('currentUser');
                localStorage.removeItem('user');
                localStorage.removeItem('permissions');
                localStorage.removeItem('companyName');
  }


  checkBackend(){
    let url = window.location.host;
    const host = appConfig.appDomain;
    var portalName = url.split('.'+host+'.'); // get string before .xeno.

    if(portalName[0] !=url){
    this.authenticationService.checkPortalname(portalName[0]).subscribe(
   (response) => {
     if (response.apiStatusCode === 0 ) {
      localStorage.removeItem('user');
                localStorage.removeItem('currentUser');
                localStorage.removeItem('user');
                localStorage.removeItem('permissions');
                localStorage.removeItem('companyName');
      this.router.navigate(['/login']);

     }
   },
   (error) => {
    // this.router.navigate(['/error-message']);
   }
 );
  }
  }
}
