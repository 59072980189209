/**
 * 20210201 CV 206-4880 Added router link and main link for cost center component
 * 20201014 CV 203-4872 Added router link and main link for sub department component
 * 20201021 CV 204-4875 Added path for section and give permission.
 * 20201104 CV 202-4866 Moved department sub menu to from employee setup to general setup.
 * 20201130 GC 190-4871 Added subscription and settings menu items
 * 20210212 GC 230-4893 Added employee bulk upload menu item
 * 20210216 GC 189-4864 changed org to comp
 * 20210216 GC 189-4864 Changed prefix org to comp
 * 20210311 GC 239-4902 Added menu items for reporting
 * 20210423 SR 206-4880 Menu indexing changed to avoid multiple selections
 * 20210504 GC 242-4906 Added menu item for turnover report
 * 20210512 SR 239-4906 Removed extra menu items form report
 * 20210608 GC 196-4915 Added menu item for calendar settings
 * 20210616 SR 241-4909 Added menu items for employee category
 * 20210715 SR 160-4916 Manage Event Types added
 * 20210708 SR 264-4931 Manage Regulations related API Endpoints
 * 20210707 SR 183-4929 Add Regulation  added
 * 20210811 SR 669-4955 Menu improvement added
 * 20210825 SR 720-4958 Menu improvement for regulations added
 * 20210831 RP 500-4938 Added menu items shifts
 * 20211012 GC 697-4967 Added shift related menu items
 * 20211028 GC 795-4969 Change organization to company
 * 20211104 GC 720-4958 Changed menu placement for regulations
 * 20231030 SV XENOV2-436 : Leave configuration development
 * 20231108 SS XENOV2-432 5172 UserRole Menu Item Added
 * 20231206 SS XENOV2-425 Temporary permission change
 * 20231215 SS XENOV2-425 permission changes
 * 20231220 SS XENOV2-425 changing router link of dashboard to leave dashboard 
 *              and removed leave dashboard from leave module
 * 20231228 SS XENOV2-425 removed cost center from main menu due to incomplete development
 * 20240104 SS 468-5190 Added Bulk Upload Employee to Main Menu
 * 20240116 SS 436-5195 Configuration menu items added
 * 20240312 SS XENOV2-625 Add Event Journal menus
 * 20240311 SV XENOV2-616 Attendance bulk upload
 * 20240314 SV XENOV2-616 Added Permissions ATD_MODULE, ATD_UPLOAD_BULK, ATD_DAILY_VIEW
 * 20240401 SV XENOV2-638 MyLeave dashboard
 * 20240724 SV XENOV2-752 Configure company wise attendance records
 * 20240725 SS 753-5260 Added Reports to Attendance
 * 20240731 SS 752-5249 Front end development of attendance time configuration
 * 20240801 SS 661 Payroll Permissions
 * 20240801 SS Added permission 'LEAVE_EVENT_JOURNAL'
 * 20240822 HJ 777-5273 Added ISO Documentation menu item
 * 20240828 SV XENOV2-764 payroll ui improvements - Point to payroll-process
 */

import { MenuBar } from './main-menu-bar';


export class MainMenuItems {

    get mainMenuenuItems(): MenuBar[] {
      return [
        {
          title: 'Subscription',
          icon: 'assets/img/subscribe.png',
          routerLink: '/subscription',
          menuIndex: 0,
          mainLink: 'subscription',
          permissions: 'EDIT_SUBSCRIPTION',
          disable: false,
          id: 'subscription',
          subMenu: []
        },
        {
          title: 'Dashboard',
          icon: 'assets/img/dashboard.png',
          routerLink: '/dashboard',
          menuIndex: 2,
          mainLink: 'dashboard',
          permissions: 'XENO_DASHBOARD',
          disable: false,
          id: 'dashboard',
          subMenu: []
        },
        {
          title: 'Company',
          icon: 'assets/img/organization.png',
          routerLink: '/company',
          menuIndex: 7,
          mainLink: 'company',
          permissions: 'VIEW_COMPANY',
          disable: false,
          id: 'company',
          subMenu: [
            {
            title: 'General Setup',
            icon: '',
            routerLink: '../company',
            menuIndex: 71,
            mainLink: '../company',
            permissions: 'COMP_GENERAL_SETUP',
            disable: false,
            id: 'genaralsetup',
            subMenu: [
              {
                title: 'Company Details',
                icon: '',
                routerLink: '../company/genaral/company-details',
                menuIndex: 711,
                mainLink: '../company',
                permissions: 'COMP_COMPANY_DETAILS',
                disable: false,
                id: 'companyDetails',
                subMenu: []
              },
              {
                title: 'Department',
                icon: '',
                routerLink: '../company/department/all',
                menuIndex: 712,
                mainLink: '../company',
                permissions: 'ORG_VIEW_DEPARTMENTS',
                disable: false,
                id: 'department',
                subMenu: []
              },
              {
                title: 'Sub Department',
                icon: '',
                routerLink: '../company/sub-department/all',
                menuIndex: 713,
                mainLink: '../company',
                permissions: 'ORG_VIEW_SUB_DEPARTMENTS',
                disable: false,
                id: 'subDepartment',
                subMenu: []
              },
              {
                title: 'Section',
                icon: '',
                routerLink: '../company/section/all',
                menuIndex: 714,
                mainLink: '../company',
                permissions: 'ORG_VIEW_SECTIONS',
                disable: true,
                id: 'section',
                subMenu: []
              },
              // {
              //   title: 'Cost Center',
              //   icon: '',
              //   routerLink: '../company/cost-center/all',
              //   menuIndex: 715,
              //   mainLink: '../company',
              //   permissions: 'RESTRICTED',
              //   disable: true,
              //   id: 'cost-center',
              //   subMenu: []
              // },
              {
                title: 'Designation',
                icon: '',
                routerLink: '../company/designation/all',
                menuIndex: 716,
                mainLink: '../company',
                permissions: 'ORG_VIEW_DESIGNATIONS',
                disable: false,
                id: 'designation',
                subMenu: []
              },
              {
                title: 'User Roles',
                icon: '',
                routerLink: '../company/genaral/manage-user-roles',
                menuIndex: 717,
                mainLink: '../company',
                permissions: 'COMP_CONFIG_USERROLE',
                disable: false,
                id: 'userroles',
                subMenu: []
              }
             ]
            },
          ]
        },
        {
          title: 'Employees',
          icon: 'assets/img/employees.png',
          routerLink: '/employee',
          menuIndex: 3,
          mainLink: 'employee',
          permissions: 'VIEW_EMPLOYEE',
          disable: false,
          id: 'employee',
          subMenu: [
            {
              title: 'All Employees',
              icon: '',
              routerLink: '../employee/all',
              menuIndex: 31,
              mainLink: 'employee',
              permissions: 'VIEW_EMPLOYEE_ALL',
              disable: false,
              id: 'allEmployee',
              subMenu: []
            },
            {
              title: 'Add Employee',
              icon: '',
              routerLink: '../employee/add/basic-details',
              menuIndex: 32,
              mainLink: 'employee/add',
              permissions: 'VIEW_BASIC_DETAILS',
              disable: false,
              id: 'addEmployee',
              subMenu: []
            },
            {
              title: 'Employee Bulk Upload',
              icon: '',
              routerLink: '../employee/upload-bulk-employees',
              menuIndex: 33,
              mainLink: 'employee',
              permissions: 'UPLOAD_BULK_EMPLOYEES',
              disable: false,
              id: 'bulkupload',
              subMenu: []
            },
          ]
        },
        {
          title: 'Leave Management',
          icon: 'assets/img/leave.png',
          routerLink: '../leave-management',
          menuIndex: 8,
          mainLink: 'leave',
          permissions: 'LEAVE_MODULE',
          disable: false,
          id: 'leave',
          subMenu: [
            {
              title: 'Dashboard',
              icon: '',
              routerLink: '../leave-management/leave-dashboard',
              menuIndex: 81,
              mainLink: 'leave',
              permissions: 'LEAVE_DASHBOARD',
              disable: false,
              id: 'lmsdashboard',
              subMenu: []
            },
            {
              title: 'Leave Configuration',
              icon: '',
              routerLink: '../leave-management/leave-configuration',
              menuIndex: 85,
              mainLink: '../leave-management',
              permissions: 'LEAVE_CONFIGURATION',
              disable: false,
              id: 'leaveconfiguration',
              subMenu: []
            },
            {
              title: 'Leave Journey',
              icon: '',
              routerLink: '../leave-management/leave-journal',
              menuIndex: 82,
              mainLink: '../leave-management',
              permissions: 'LEAVE_EVENT_JOURNAL',
              disable: false,
              id: 'leavejournal',
              subMenu: []
            },
            {
              title: 'Apply Leave',
              icon: '',
              routerLink: '../leave-management/apply-leave',
              menuIndex: 83,
              mainLink: 'leave',
              permissions: 'LEAVE_APPLY',
              disable: false,
              id: 'applyleave',
              subMenu: []
            },
            {
              title: 'Leave Applicants',
              icon: '',
              routerLink: '../leave-management/leave-applicants',
              menuIndex: 84,
              mainLink: 'leave',
              permissions: 'LEAVE_APPLICANTS',
              disable: false,
              id: 'leaveapplicants',
              subMenu: []
            },
          ]
        },
        {
          title: 'Attendance',
          icon: 'assets/img/today.png',
          routerLink: '../attendance',
          menuIndex: 4,
          mainLink: '../attendance',
          permissions: 'ATD_MODULE',
          disable: false,
          id: 'attendance',
          subMenu: 
          [
            {
              title: 'Attendance Time',
              icon: '',
              routerLink: '../attendance/attendance-configuration',
              menuIndex: 44,
              mainLink: '../attendance',
              permissions: 'ATD_VIEW_TIME_CONFIG',
              disable: false,
              id: 'attendanceconfiguration',
              subMenu: []
            },
            {
              title: 'Daily Attendance View',
              icon: '',
              routerLink: '../attendance/daily-attendance-view',
              menuIndex: 42,
              mainLink: '../attendance',
              permissions: 'ATD_DAILY_VIEW',
              disable: false,
              id: 'attendancebulk',
              subMenu: []
            },
            {
              title: 'Attendance Bulk Upload',
              icon: '',
              routerLink: '../attendance/upload-bulk-attendance',
              menuIndex: 41,
              mainLink: '../attendance',
              permissions: 'ATD_UPLOAD_BULK',
              disable: false,
              id: 'attendancebulk',
              subMenu: []
            },
            {
              title: 'Reports',
              icon: '',
              routerLink: '../attendance/reports',
              menuIndex: 43,
              mainLink: '../attendance',
              permissions: 'ATD_REPORT',
              disable: false,
              id: 'attendancereports',
              subMenu: []
            },
          ]
        },
        {
          title: 'I-context',
          icon: 'assets/img/icontext-icon.png',
          routerLink: '../i-context/landing-page',
          menuIndex: 5,
          mainLink: '../i-context',
          permissions: 'I_CON_MODULE',
          disable: false,
          id: 'i-context',
          subMenu: []
        },
        {
          title: 'Payroll Management',
          icon: 'assets/img/payroll.png',
          routerLink: '../payroll',
          menuIndex: 6,
          mainLink: 'payroll',
          permissions: 'PAYROLL_MODULE',
          disable: false,
          id: 'payroll',
          subMenu: [
            {
              title: 'Payroll',
              icon: '',
              routerLink: '../payroll/payroll-process',
              menuIndex: 61,
              mainLink: 'payroll',
              permissions: 'PAYROLL_DASH',
              disable: false,
              id: 'payroll',
              subMenu: []
            },
            {
              title: 'Payroll Configuration',
              icon: '',
              routerLink: '../payroll/payroll-configuration',
              menuIndex: 62,
              mainLink: 'payroll',
              permissions: 'PAYROLL_CONFIG',
              disable: false,
              id: 'payrollconfiguration',
              subMenu: []
            },
            {
              title: 'Payroll History',
              icon: '',
              routerLink: '../payroll/payroll-history',
              menuIndex: 66,
              mainLink: 'payroll',
              permissions: 'PAYROLL_HIS',
              disable: false,
              id: 'payrollhistory',
              subMenu: []
            },
          ]
        },
        {
          title: 'Settings',
          icon: 'assets/img/settings_s.png',
          routerLink: '/settings',
          menuIndex: 1,
          mainLink: 'dashboard',
          permissions: 'VIEW_SETTINGS',
          disable: false,
          id: 'settings',
          subMenu: []
        },
        {
          title: 'ISO Documentation',
          icon: 'assets/img/list.png',
          routerLink: '/iso-documentation',
          menuIndex: 9,
          mainLink: 'dashboard',
          permissions: 'ISO_DOCS_MODULE',
          disable: false,
          id: 'iso',
          subMenu: []
        },
      ];
    }
  }
