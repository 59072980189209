/**
 * 20210113 GC 222-4887 Add disableLogin()
 * 20210106 GC 189-4864 Added getTokenFromUrl(), disableForm()
 * 20210112 GC 189-4864 Added portalLogin(), genaralLogin()
 * 20210113 GC 189-4864 Changed genaralLogin to nonPortalLogin()
 * 20210211 CV 207-4878 Added locked account inline validation messages.
 * 20210225 PU 208-4891 Changed checkPortalName() error message, renamed f() function to controls() for readability
 * 20210219 PU 291-4911 added common error handling
 * 20210319 GC 239-4902 Added fake login functionality
 * 20231215 SS XENOV2-425 error notification without routing to a page
 * 20231221 SV XENOV2-425 Landing Page scrolling part
 * 20240822 SS Hidden Signup button 
 */

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { first } from 'rxjs/operators';
import { ErrorPageService } from '../../core/services/error-page.service';
import { NotificationUtils } from 'src/app/core/util/notification-util';
import { appConfig } from 'src/environments/environment';

import * as rsx from 'rxjs'
import { MainMenuSelectService } from 'src/app/core/services/main-menu-select.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  portalName;
  logonToken;
  wrongToken = false;
  compStatusChecked = false;
  tabIndex = 1;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private errorService: ErrorPageService,
    private notificationUtils: NotificationUtils,
    private mainMenuService: MainMenuSelectService,
  ) {

    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.mainMenuService.setCurrentIndex(2);
      this.router.navigate(['/']);
    }

    this.route.params.subscribe( params => this.portalName = params.portal);
  }

  ngOnInit() {
    this.checkPortalName();
    setTimeout(()=>{
      if(!this.portalName){

      }
      this.getTokenFromUrl();
      console.log(this.portalName);
      this.disableForm();
    },1000);

    // this.doAsyncCall();
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    //Move focus on next field when enter is pressed
    $(document).on('keypress', 'input,select', function (e) {
      if (e.key == "Enter") {
        e.preventDefault();
        var $next = $('[tabIndex=' + (+this.tabIndex + 1) + ']');

        $next.focus();
      }
    });

  }
  doAsyncCall(){
    this.checkPortalName();
    this.doCallBackCall(() =>{

    });
  }
  doCallBackCall(_callback){
    this.getTokenFromUrl();
    _callback();
  }

  // convenience getter for easy access to form fields
  get controls() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
        if(this.portalName){ // If portal name exist, do portal login
          this.portalLogin();
        } else if(this.logonToken){
          this.nonPortalLogin();
        }
  }

  /**
   * get the url and grab portal name from it to verify
   */
  checkPortalName(){

    let url = window.location.host;
    const host = appConfig.appDomain;
    var portalName = url.split('.'+host+'.'); // get string before .xeno.

    if(portalName[0] !=url){
      this.portalName = portalName[0];
      this.authenticationService.checkPortalname(portalName[0]).subscribe(
        (response) => {
          if (response.apiStatusCode === 0 && response.data.companyStatus === "COMPI"){  // If the company is inactive
            this.errorService.setLastUrl('/login');
            this.notificationUtils.showErrorMessage(undefined, 'Inactive company');
          }
        },
        (error) => {
          this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
        }
      );
    } else {
      this.portalName = null;
    }
    return "";
  }

  scrollToSection(sectionId) {
    let section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }
  
  /**
  * Handle the login functionality for portal logon
  */
  portalLogin() {
     const loginRequest = {
       username: this.controls.username.value,
       password: this.controls.password.value,
       portalName: this.portalName
     }

     this.authenticationService.getUserToken(loginRequest).subscribe(
       (res) => {
         if (res.apiStatusCode === 0) { // with the response create user object with available data
           this.loading = false;
           const permissionRes = res.additionalParams;
           let permissions = [];
          permissions = permissionRes.permissions

          const user = {
             cmpCode: res.data.cmpCode,
             firstName: '',
             id: '',
             lastName: '',
             permissions: permissions, // set available permissions
             role: '',
             token: res.data.jwtToken,
             jwtToken:res.data.jwtToken,
             username: res.data.username,
             companyName:  res.data.companyName
           };

           const empStatus = res.data.empStatus;

          this.authenticationService.initialLogin(user, empStatus); // DO initial login
        }
      },
      (error) => {
        this.error = error.errorMessage;
        this.loading = false;
      }
    );
  }

  /**
   * Handle functionality for non-portal login with token in URL
   */
  nonPortalLogin(){
    let loginRequest = {
      password:this.controls.password.value,
      token:this.logonToken,
      username: this.controls.username.value
    }

    this.authenticationService.logUser(loginRequest).subscribe(
      (res) => {
        if (res.apiStatusCode === 0) { // with the response create user object with available data
          this.loading = false;
          const permissionRes = res.additionalParams;
          let permissions = [];
         permissions = permissionRes.permissions

          const user = {
            cmpCode: res.data.cmpCode,
            firstName: '',
            id: '',
            lastName: '',
            permissions: permissions, // set available permissions
            role: '',
            token: res.data.token,
            jwtToken:res.data.jwtToken,
            username: res.data.email,
            companyName:  res.data.companyName
           };

          this.authenticationService.initialLogin(user, ''); // DO initial login

        }
        if (res.apiStatusCode === 17) { // If user has portal link
          this.notificationUtils.showErrorMessage(undefined, res.message);
        } else if (res.apiStatusCode === 15) { // If link is inactive
          this.notificationUtils.showErrorMessage(undefined, res.message);
        } else if (res.apiStatusCode === 7) { // If link is invalid
          this.notificationUtils.showErrorMessage(undefined, res.message);
        }
      },
      (error) => {
        this.error = error.errorMessage;
        this.loading = false;
        if (error.errorCode === 409) {
          this.notificationUtils.showErrorMessage(undefined, this.error);
        } else if (!(error.errorCode === 400 || error.errorCode === 404)) {
          this.notificationUtils.showErrorMessage(undefined, this.error);
        }
      }
    );
  }

/**
 * check for query params and get token if exist.
 */
  getTokenFromUrl(){
    this.route.queryParams.subscribe(params => {
      this.logonToken = params['token'];
      this.compStatusChecked = true;
      if(this.logonToken){this.verifyToken(this.logonToken);}

    });
    sessionStorage.setItem('logonToken', this.logonToken);

  }

/**
 * Disable login button if any field empty or null
 */
disableLogin() {
  return (this.controls.username.value === '' || this.controls.username.value === null ||
    this.controls.password.value === '' || this.controls.password.value === null);
}

  /**
   * Disable form if its not a portal login or login url with token
   */
  disableForm(){
    if(!this.logonToken && !this.portalName){
      this.loginForm.disable();
    }

  }

  isShowSignUp(): boolean {
    return localStorage.getItem('showSignUp') ? localStorage.getItem('showSignUp') === 'true' : false;
  }

  login(){
    let loginRequest = {
      username:this.controls.username.value,
      password:this.controls.password.value,
      portalName:this.portalName
    }

    this.authenticationService.getUserToken(loginRequest).subscribe(
      (res) => {
        if (res.apiStatusCode === 0) { // with the response create user object with available data

          const user = {
            cmpCode: res.data.cmpCode,
            firstName: '',
            id: '',
            lastName: '',
            permissions: '', // set available permissions
            role: 'Admin',
            token: res.data.jwtToken,
            username: res.data.username,
            companyName:  res.data.companyName
           };


          this.authenticationService.initialLogin(user,''); // DO initial login

        }
      },
      (error) => {
        if (error.errorCode === 409) {
          this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
        } else if (!(error.errorCode === 400 || error.errorCode === 404)) {
          this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
        }
      }
    );
  }

   //submit the form when the user hits the enter button in the last form field.
   keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }

  userNameKeydown(event){
    if (event.keyCode === 13) {
      var $next = $('[tabIndex=' + (+this.tabIndex + 1) + ']');
      $next.focus();
    }
  }


  verifyToken(token: string) {

    this.authenticationService.getEmail(token).subscribe(
      (response) => {
        if (response.apiStatusCode === 17) { // If user has portal link
          this.notificationUtils.showErrorMessage(undefined, "Invalid Link");
        } else if (response.apiStatusCode === 15) { // If link is inactive
          this.notificationUtils.showErrorMessage(undefined, "Invalid Link");
        } else if (response.apiStatusCode === 7) { // If link is invalid
          this.notificationUtils.showErrorMessage(undefined, response.message);
        } else  if (response.apiStatusCode === 14) { // If terms and conditions pending
          this.router.navigateByUrl('login?token='+ token);
        } else {

          console.log("client ip : " + response.ip);
          if (localStorage.getItem('user')) {
            this.router.navigateByUrl('login?token='+token);
          }

        }
      },
      (error) => {
        if (error.errorCode === 404) {
         this.wrongToken = true;
         this.loginForm.disable();
        }
      });

  }


}
