/**
 * 20210707 RP 493-4937 added this class for the shift types details
 * 20210802 PU 506-4940 added shift type id
 */
 export class ShiftType {
  id: number;
  name: string;
}

export const SHIFTTYPES: ShiftType[] = [
    {
      id: 1,
      name: 'Fixed Time'
    },
    {
      id: 2,
      name: 'Flexible Time'
    },
    {
      id: 3,
    name: 'Day Off'
  }
];
