/**
 * 20201011 CV 126-4845 created this class to validate the user details
 * 20201023 173-4850 GC fixed lint issues
 * 20201125 173-4850 CV Fixed password match validation message issue.
 */
import { ValidationErrors, ValidatorFn, AbstractControl, ControlContainer } from '@angular/forms';

export class CustomValidators {
    static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
          if (!control.value) {
            // if control is empty return no error
            return null;
          }

          // test the value of the control against the regexp supplied
          const valid = regex.test(control.value);

          // if true, return no error (no error), else return error passed in the second parameter
          return valid ? null : error;
        };
      }

      static passwordMatchValidator(control: AbstractControl) {
        const password: string = control.get('password').value; // get password from our password form control
        const confirmPassword: string = control.get('confirmPassword').value; // get password from our confirmPassword form control
        // compare is the password math
        if (password !== confirmPassword && confirmPassword !== null && confirmPassword !== '') {
          // if they don't match, set an error in our confirmPassword form control
          control.get('confirmPassword').setErrors({ NoPassswordMatch: true });
        }
        if (password === confirmPassword && password !== null && confirmPassword !== null && password !== '' && confirmPassword !== ''){
          control.get('confirmPassword').setErrors(null);
        }


      }

      static equaltoUsernameValidator(control: AbstractControl) {
        const password: string = control.get('password')? control.get('password').value : null; // get password from our password form control
        const userName: string = control.get('email') ? control.get('email').value : null; // get username from our email form control
        if (password === userName) {
          // if they match, set an error in our passord form control
          if( control.get('password')){
            control.get('password').setErrors({ equalToUsername: true });
            return {equalToUsername: true};
          }
         
        }

      }


}
