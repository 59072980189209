/**
 * 20201011 RP 126-4845 created this class for the Rest services 
 * 20201023 173-4850 GC added comments & removed unused import
 */
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { ENDPOINTS } from '../util/api-endpoints';
import { appConfig } from 'src/environments/environment';

@Injectable()
export class ApiService {

    constructor(private httpService: HttpService) {
    }

    // call Update password API
    updatePassword(body: any) {
        const url = appConfig.appRoot.concat(ENDPOINTS.updatePassword);
        return this.httpService.httpPut(url, body, {observe : 'response'});
    }

    /**
     * 20231122 SS XENOV2-434 5169 createUserPassword
     *  
     * @param body 
     * @returns 
     */
    createUserPassword(body: any) {
        const url = appConfig.appRoot.concat(ENDPOINTS.createUserPassword);
        return this.httpService.httpPut(url, body, {observe : 'response'});
    }

    // Get user email by token
    getEmail(token:string) {
        const url = appConfig.appRoot.concat(ENDPOINTS.getEmail.replace("${token}",token));
        return this.httpService.httpGet(url);
    }

    // Get credentials
    getCredentials(body:any){
        const url = appConfig.appRoot.concat(ENDPOINTS.getCredentials);
        return this.httpService.httpPost(url, body, {observe : 'response'});
    }
}