/**
 * 20201117 GC 191-4873  added comments
 * 20201125 GC 191-4873 Added checkbox behaviours
 * 20201130 GC 191-4873 Added onElementScroll(), onLoadTerms()
 * 20210101 GC 222-4887 Added acceptTerms(), show() methods
 * 20210104 GC 222-4887 Added navigateToLogin(), clearSession() methods
 * 20210219 PU 291-4911 added common error handling
 * 20231215 SS XENOV2-425 error notification without routing to a page
 * 20240806 SS Removed the pdf viewer and developed the logic
 */

import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreApiService } from '../../services/api.service';
import { DOCUMENT } from '@angular/common';
import { NotificationUtils } from 'src/app/core/util/notification-util';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

@Component({
    selector: 'app-subscription',
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
    termsForm: FormGroup;
    isTermsDisabled = true;
    termsApproved = false;
    numberOfPages = 0;
    showModal: boolean;
    currentUser = localStorage.getItem('currentUser');
    responseData;
    termsCompleted; // parameter to show hide pdf
    // Use ViewChild to get a reference to the scrollable div
    @ViewChild('scrollableDiv', { static: true }) scrollableDiv: ElementRef;

    constructor(
        private formBuilder: FormBuilder,
        private apiService: CoreApiService,
        @Inject(DOCUMENT) private document: Document,
        private notificationUtils: NotificationUtils,
        private authenticationService: AuthenticationService,
    ) { }

    ngOnInit() {
        this.termsForm = this.formBuilder.group({ // create form
            acceptTerms: [false, Validators.requiredTrue],
            download: [false, '']
        });

        let perms = localStorage.getItem('permissions').split(',');
      
        if (perms.indexOf('VIEW_COMPANY') !== -1) {
            this.termsCompleted = true;
        }
    }

    // Method to check if the user has scrolled to the bottom
    onScroll(event: any) {
        const element = event.target;
        const scrollPosition = element.scrollHeight - element.scrollTop;
        const offsetHeight = element.offsetHeight;

        // Check if the scroll position is at the bottom
        if (scrollPosition === offsetHeight) {
            this.isTermsDisabled = false;
        }
    }
    
    /**
    *
    * On after load pdf, event triggers and return number of pages
    */
    onLoadTerms(event) {
        this.numberOfPages = event;
    }


    /**
    *
    * Check the value of I agree checkbox
    */
    checkValue(event) {
        if (this.termsForm.value.acceptTerms) {
            this.termsApproved = true;
        } else {
            this.termsApproved = false;

        }

    }

    /**
    * Confirm terms and conditions agreement
    */
    acceptTerms(){
        const token =  JSON.parse(localStorage.getItem('user'));
        const requestBody = { "token" : token.token}
        if (this.termsForm.value.download) { //If user checked download checkbox
            var a = document.createElement("a"); // Create anchor tag and programatically click it for download file
            a.href = 'assets/terms_cond/terms-and-conditions.pdf';
            a.target = '_blank';
            a.download = "assets/terms_cond/terms-and-conditions.pdf";
            a.click();
        }
        this.apiService.acceptTerms(requestBody).subscribe(
            (res) => {
                if (res.apiStatusCode === 0) { // with the response create user object with available data
                console.log(res);
                this.responseData = res.data;
                this.authenticationService.updatePermissions(res.additionalParams.permissions);
                this.show();  // Show information popup messege
                }
            },
            (error) => {
                this.notificationUtils.showErrorMessage(undefined, error.errorMessage);
            }
        );
    }

    /**
     * show event for information popup
     */
    show() {
        this.showModal = true; // Show-Hide Modal Check
    }

    /**
     * Clear local storage and move to portal login page
     */
    navigateToLogin(){
        this.clearSession();
        this.document.location.href = 'http://'+this.responseData.portalName; // redirect to portal login
    }

    /**
     * clear user from local storage
     */
    clearSession(){
        localStorage.removeItem('currentUser');
        localStorage.removeItem('user');
        localStorage.removeItem('permissions');
    }

}
