/**
 * 20201011 RP 126-4845 add company sign up and verification page routing
 * 20201021 RP 126-4845 remove the sign verification page
 * 20201023 GC 173-1023 added comments
 * 20201113 GC 170-4843 Merge latest for main-router
 * 20201023 GC 173-4850 added comments
 * 20201119 GC 190-4871 changed guest-dashboard to landing-page
 * 20210202 CV 191-4873 Added route for disable dashboard
 * 20210216 GC 189-4864 Changed prefix org to comp
 * 20210113 GC 222-4887 Removed param from login route
 * 20210115 GC 222-4887 Added settings route
 * 20210218 PU 291-4911 moved ErrorComponent to core module
 * 20210202 CV 208-4891 Added password reset link request and password reset page routing
 * 20210311 GC 239-4902 Added reporting module
 * 20210504 GC 242-4906 Added main router for reports
 * 20211028 GC 795-4969 Change Refactor router
 * 20231030 SV XENOV2-436 : Leave configuration development
 * 20231124 SS XENOV2-436 Changed the landing page to the DashboardComponent
 * 20231206 SS XENOV2-425 Temporary permissions change
 * 20231215 SS XENOV2-425 permission changes
 * 20231220 SS XENOV2-425 changing router link of dashboard to leave dashboard 
 *              and removed leave dashboard from leave module
 * 20240307 SV XENOV2-640 Added Terms and Conditions page and Privacy Policy page
 * 20240314 SV XENOV2-616 Added Permission ATD_MODULE
 * 20240401 SV XENOV2-638 MyLeave dashboard
 * 20240422 SV XENOV2-638 Added permission XENO_DASHBOARD
 * 20240801 SS 661 Payroll Permissions
 * 20240822 HJ 777-5273 Added ISO Documentation route
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullLayoutComponent } from '../layout/full-layout/full-layout.component';
import { AuthGuard } from '../guards/auth.guard';
import { LoginComponent } from 'src/app/modules/login/login.component';
import { OrganizationInitialSetupComponent } from 'src/app/modules/organization/components/organization-initial-setup/organization-initial-setup.component';
import { SignupComponent } from 'src/app/modules/signup/components/signup/signup.component';
import { ErrorComponent } from 'src/app/core/components/error-message/error-message.component';
import { SubscriptionComponent } from 'src/app/core/components/subscription/subscription.component';
import { SettingsComponent} from 'src/app/core/components/settings/settings.component';
import { DoForgotpwdComponent } from 'src/app/modules/login/components/do-forgotpwd/do-forgotpwd.component';
import { DoResetpwdComponent } from 'src/app/modules/login/components/do-resetpwd/do-resetpwd.component';
import { PrivacyPolicyComponent } from 'src/app/modules/login/components/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from 'src/app/modules/login/components/terms-conditions/terms-conditions.component';
import { CommonDashboardComponent } from '../components/common-dashboard/common-dashboard.component';


// Add main route items
const routes: Routes = [
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'employee',
        loadChildren: '../../modules/employee/employee.module#EmployeeModule',
        canActivate: [AuthGuard],
        data: { permission: "VIEW_EMPLOYEE" }
      },
      {
        path: 'company',
        loadChildren: '../../modules/organization/organization.module#OrganizationModule',
        canActivate: [AuthGuard],
        data: { permission: "VIEW_COMPANY" }
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        component: CommonDashboardComponent,
        data: { permission: "XENO_DASHBOARD" }
      },
      {
        path: 'subscription', // route for disable dashboard
        canActivate: [AuthGuard],
        component: SubscriptionComponent
      },
      {
        path: 'settings', // route for disable dashboard
        canActivate: [AuthGuard],
        component: SettingsComponent,
        data: { permission: "VIEW_SETTINGS" }
      },
      {
        path: 'leave-management',
        loadChildren: '../../modules/leave-management/leave-management.module#LeaveManagementModule',
        canActivate: [AuthGuard],
        data: { permission: "LEAVE_MODULE" }
      },
      {
        path: 'attendance',
        loadChildren: '../../modules/attendance/attendance.module#AttendanceModule',
        canActivate: [AuthGuard],
        data: { permission: "ATD_MODULE" }
      },
      {
        path: 'payroll',
        loadChildren: '../../modules/payroll/payroll.module#PayrollModule',
        canActivate: [AuthGuard],
        data: { permission: "PAYROLL_MODULE" }
      },
      {
        path: 'i-context',
        loadChildren: '../../modules/i-context/i-context.module#IcontextModule',
        canActivate: [AuthGuard],
        data: { permission: "I_CON_MODULE" }
      },
      {
        path: 'iso-documentation',
        loadChildren: '../../modules/iso-documents/iso-documents.module#IsoDocumentsModule',
        canActivate: [AuthGuard],
        data: { permission: "ISO_DOCS_MODULE" }
      },
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'company-signup', component: OrganizationInitialSetupComponent },
  { path: 'forgot-pwd', component: DoForgotpwdComponent },
  { path: 'user-login/:token/forgot-pwd', component: DoForgotpwdComponent },
  { path: 'error-message', component: ErrorComponent}, // route for error component
  { path: 'user-sign-up/:token', component: SignupComponent }, // route for signup page
  { path: 'login/resetpwd', component: DoResetpwdComponent }, // route for reset password page
  { path: 'privacy-policy', component: PrivacyPolicyComponent }, // route for privacy policy page
  { path: 'terms-and-conditions', component: TermsConditionsComponent }, // route for terms and conditions page
  // otherwise redirect to home
 // { path: '**', redirectTo: '' }
];

@NgModule({ // On same url navigateion, set for reload route
  imports: [RouterModule.forRoot(routes, { enableTracing: false, onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class MainRouterModule { }
