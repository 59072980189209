/**
 * 20210621 SR 160-4916 Manage event type related services added
 * 20210615 SR 265-4921 Added methods related to add event screen
 */

import { Injectable } from "@angular/core";
import { HttpService } from '../../../core/http/http.service';
import { appConfig } from '../../../../environments/environment';
import { ENDPOINTS } from "../util/api-endpoints";

@Injectable()
export class CalanderEventService{
    constructor (private httpService: HttpService){
    }
    
    getEventTypes(status){
        const url = appConfig.appRoot.concat(ENDPOINTS.getEventTypesByStatus).replace('{status}', status); 
        return this.httpService.httpGet(url);
    }

    getDepartments(){
        const url = appConfig.appRoot.concat(ENDPOINTS.getDepartments).replace('{status}', '');
        return this.httpService.httpGet(url);
    }
    getSubDepartments(){
        const url = appConfig.appRoot.concat(ENDPOINTS.getSubDepartments).replace('{status}', '');
        return this.httpService.httpGet(url);
    }

    getSections(){
        const url = appConfig.appRoot.concat(ENDPOINTS.getSections);
        return this.httpService.httpGet(url);
    }

    addCompanyEvents(body){
        const url = appConfig.appRoot.concat(ENDPOINTS.addCompanyEvents);
        return this.httpService.httpPost(url, body);
    }
   
}